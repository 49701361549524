import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../cssfiles/JSONContent.css'; // Importing CSS file

const JSONWithJava = () => {
  const personExample = `import org.json.JSONObject;

JSONObject person = new JSONObject();
person.put("name", "Alice");
person.put("age", 30);
person.put("city", "New York");

String personJsonString = person.toString();
System.out.println(personJsonString);  // Output: {"name":"Alice","age":30,"city":"New York"}`;

  const productExample = `import org.json.JSONArray;
import org.json.JSONObject;

JSONObject product = new JSONObject();
product.put("id", 101);
product.put("name", "Smartphone");
product.put("price", 699.99);
product.put("features", new JSONArray()
    .put("Touchscreen")
    .put("WiFi")
    .put("Bluetooth"));

String productJsonString = product.toString();
System.out.println("Product JSON String: " + productJsonString);  // Output: Product JSON String: {"id":101,"name":"Smartphone","price":699.99,"features":["Touchscreen","WiFi","Bluetooth"]}`;

  const locationExample = `import org.json.JSONObject;

JSONObject location = new JSONObject();
location.put("latitude", 40.7128);
location.put("longitude", -74.0060);
location.put("city", "New York");
location.put("country", "USA");

String locationJsonString = location.toString();
System.out.println("Location JSON String: " + locationJsonString);  // Output: Location JSON String: {"latitude":40.7128,"longitude":-74.006,"city":"New York","country":"USA"}`;

  const [copied, setCopied] = useState('');

  // Function to copy JSON content to clipboard
  const handleCopy = (example) => {
    navigator.clipboard.writeText(example).then(() => {
      setCopied('Copied!'); // Set copied state to display feedback
      setTimeout(() => setCopied(''), 2000); // Reset copied state after 2 seconds
    });
  };

  return (
    <div className="json-examples-container">
        <h2>JSON with JAVA</h2>
     <p>JSON (JavaScript Object Notation) is often used with Java to handle data. Here's a brief overview with examples </p>
  
      <section>
        <h2>Person Example</h2>
        <p><strong>JSONObject</strong> is a class in the org.json library that allows you to create and manipulate JSON objects in Java.</p>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(personExample)}>
            {copied === 'Copied!' ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="java" style={atomDark} className="code-block">
            {personExample}
          </SyntaxHighlighter>
        </div>
        <p>This example creates a JSON object representing a person with properties such as name, age, and city. It then converts the object to a JSON string and prints it to the console.</p>
      </section>
      
      <hr className="divider" />

      <section>
        <h2>Product Example</h2>
        <p><strong>JSONArray</strong> is used to create an array of JSON values, allowing for a flexible representation of data collections in Java.</p>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(productExample)}>
            {copied === 'Copied!' ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="java" style={atomDark} className="code-block">
            {productExample}
          </SyntaxHighlighter>
        </div>
        <p>This example defines a product object with properties like id, name, price, and features. It converts the product object to a JSON string and prints it to the console.</p>
      </section>
      
      <hr className="divider" />

      <section>
        <h2>Location Example</h2>
        <p><strong>JSONObject</strong> allows you to represent complex data structures in a straightforward way, making it easy to handle location data.</p>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(locationExample)}>
            {copied === 'Copied!' ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="java" style={atomDark} className="code-block">
            {locationExample}
          </SyntaxHighlighter>
        </div>
        <p>This example creates a location object with properties for latitude, longitude, city, and country. It converts the location object to a JSON string and prints it to the console.</p>
      </section>
      
    </div>
  );
};

export default JSONWithJava;
