import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../cssfiles/JSONContent.css'; // Importing CSS file
const JSONDataTypes = () => {
  const [copiedText, setCopiedText] = useState(''); // State to manage copied text feedback
  const [copied, setCopied] = useState(false); // State to manage copied feedback

  // Function to copy text to clipboard and update copied feedback
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedText(text);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
        setCopiedText(''); // Reset copied text after 2 seconds
      }, 2000);
    });
  };

  return (
    <div className="json-introduction-container">
  <h2>JSON Data Types </h2> 
       <p>In JSON, the values must adhere to one of the following data types:</p>
      <ul className="info-list">
        <li><strong>String</strong>: Represents text.</li>
        <li><strong>Number</strong>: Represents numeric values.</li>
        <li><strong>Object</strong>: A collection of key/value pairs (also referred to as a JSON object).</li>
        <li><strong>Array</strong>: An ordered list of values.</li>
        <li><strong>Boolean</strong>: Represents either true or false.</li>
        <li><strong>Null</strong>: Represents an empty value.</li>
      </ul>
      <hr className="divider" />

      <h2>Invalid Data Types in JSON</h2>
      <p>JSON values cannot be of the following data types:</p>
      <ul className="info-list">
        <li><strong>Function</strong>: Functions are not valid.</li>
        <li><strong>Date</strong>: Dates are not a supported type.</li>
        <li><strong>Undefined</strong>: Undefined is not recognized.</li>
      </ul>
      <hr className="divider" />

      {/* JSON String Section */}
      <h2>JSON Strings</h2>
      <p>
        Strings in JSON must always be enclosed in double quotes.
        <span 
          className="copy-feedback" 
          onClick={() => handleCopy('{"firstName":"Alice"}')}
          style={{ cursor: 'pointer', marginLeft: '10px' }} // Ensuring it looks clickable
        >
          {copied && copiedText === '{"firstName":"Alice"}' ? 'Copied!' : <i className="fas fa-clone"></i>}
        </span>
      </p>
      <SyntaxHighlighter language="json" style={atomDark} className="code-block">
        {"{\"firstName\":\"Alice\"}"}
      </SyntaxHighlighter>
      <hr className="divider" />

      {/* JSON Number Section */}
      <h2>JSON Numbers</h2>
      <p>
        Numbers in JSON can be either whole numbers or decimal values.
        <span 
          className="copy-feedback" 
          onClick={() => handleCopy('{"height":5.7}')}
          style={{ cursor: 'pointer', marginLeft: '10px' }}
        >
          {copied && copiedText === '{"height":5.7}' ? 'Copied!' : <i className="fas fa-clone"></i>}
        </span>
      </p>
      <SyntaxHighlighter language="json" style={atomDark} className="code-block">
        {"{\"height\":5.7}"}
      </SyntaxHighlighter>
      <hr className="divider" />

      {/* JSON Object Section */}
      <h2>JSON Objects</h2>
      <p>
        Values in JSON can be complex structures known as objects.
        <span 
          className="copy-feedback" 
          onClick={() => handleCopy(`{"student":{"firstName":"Emily","lastName":"Johnson","age":22}}`)}
          style={{ cursor: 'pointer', marginLeft: '10px' }}
        >
          {copied && copiedText === `{"student":{"firstName":"Emily","lastName":"Johnson","age":22}}` ? 'Copied!' : <i className="fas fa-clone"></i>}
        </span>
      </p>
      <SyntaxHighlighter language="json" style={atomDark} className="code-block">
        {`{
  "student": {
    "firstName": "Emily",
    "lastName": "Johnson",
    "age": 22
  }
}`}
      </SyntaxHighlighter>
      <hr className="divider" />

      {/* JSON Array Section */}
      <h2>JSON Arrays</h2>
      <p>
        Values in JSON can also be represented as arrays.
        <span 
          className="copy-feedback" 
          onClick={() => handleCopy(`{"fruits":["Apple","Banana","Cherry"]}`)}
          style={{ cursor: 'pointer', marginLeft: '10px' }}
        >
          {copied && copiedText === `{"fruits":["Apple","Banana","Cherry"]}` ? 'Copied!' : <i className="fas fa-clone"></i>}
        </span>
      </p>
      <SyntaxHighlighter language="json" style={atomDark} className="code-block">
        {`{
  "fruits": ["Apple", "Banana", "Cherry"]
}`}
      </SyntaxHighlighter>
      <hr className="divider" />

      {/* JSON Boolean Section */}
      <h2>JSON Booleans</h2>
      <p>
        Values in JSON can represent true or false.
        <span 
          className="copy-feedback" 
          onClick={() => handleCopy('{"isActive": false}')}
          style={{ cursor: 'pointer', marginLeft: '10px' }}
        >
          {copied && copiedText === '{"isActive": false}' ? 'Copied!' : <i className="fas fa-clone"></i>}
        </span>
      </p>
      <SyntaxHighlighter language="json" style={atomDark} className="code-block">
        {"{\"isActive\": false}"}
      </SyntaxHighlighter>
      <hr className="divider" />

      {/* JSON Null Section */}
      <h2>JSON Null</h2>
      <p>
        Values in JSON can be explicitly set to null.
        <span 
          className="copy-feedback" 
          onClick={() => handleCopy('{"nickname": null}')}
          style={{ cursor: 'pointer', marginLeft: '10px' }}
        >
          {copied && copiedText === '{"nickname": null}' ? 'Copied!' : <i className="fas fa-clone"></i>}
        </span>
      </p>
      <SyntaxHighlighter language="json" style={atomDark} className="code-block">
        {"{\"nickname\": null}"}
      </SyntaxHighlighter>
    </div>
  );
};

export default JSONDataTypes;
