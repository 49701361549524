import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../cssfiles/JSONContent.css'; // Importing CSS file

const JSONObjects = () => {
    const jsonExamples = [
        {
            title: "Example of a JSON String",
            json: `'{"title":"Inception", "year":2010, "director":null}'`,
            description: "This is a JSON string that contains key/value pairs. The entire content is enclosed in quotes, indicating that it is still a string."
        },
        {
            title: "JSON Object Literal",
            json: `let jsonObjectLiteral = {"title":"Inception", "year":2010, "director":null};\nconsole.log("JSON Object Literal:", jsonObjectLiteral);\n// Outputs: JSON Object Literal: { title: 'Inception', year: 2010, director: null }`,
            description: "JSON object literals are enclosed in curly braces. Inside, you have key/value pairs separated by a colon."
        },
        {
            title: "JavaScript Object",
            json: `const movie = {"title":"Inception", "year":2010, "director":null};\nconsole.log("JavaScript Object:", movie);\n// Outputs: JavaScript Object: { title: 'Inception', year: 2010, director: null }`,
            description: "This is now a JavaScript object and no longer a JSON string."
        },
        {
            title: "Parsing JSON String into JavaScript Object",
            json: `const jsonString = '{"title":"Inception", "year":2010, "director":null}';\nconst movieObj = JSON.parse(jsonString);\nconsole.log("Parsed JavaScript Object:", movieObj);\n// Outputs: Parsed JavaScript Object: { title: 'Inception', year: 2010, director: null }`,
            description: "Now, movieObj is a JavaScript object."
        },
        {
            title: "Accessing Object Values",
            json: `// JSON String
const jsonString = '{"title": "Inception", "year": 2010}';
// Parsing JSON String into JavaScript Object
const movieObj = JSON.parse(jsonString);
// Accessing Object Values
const title = movieObj.title; // Accesses "Inception"
console.log("Movie Title:", title); // Outputs: Movie Title: Inception`,
            description: "You can access values inside the JavaScript object using dot notation."
        }
        
        
    ];

    const [copiedStates, setCopiedStates] = useState(Array(jsonExamples.length).fill(false)); // Initialize copied states

    const handleCopy = (index) => {
        navigator.clipboard.writeText(jsonExamples[index].json).then(() => {
            const newCopiedStates = [...copiedStates];
            newCopiedStates[index] = true; // Set copied state for the current index
            setCopiedStates(newCopiedStates);
            setTimeout(() => {
                newCopiedStates[index] = false; // Reset after 2 seconds
                setCopiedStates(newCopiedStates);
            }, 2000);
        });
    };

    return (
        <div className="json-introduction-container">
            <h2>What is a JSON Object?</h2>
            <p>
                In <strong>JSON (JavaScript Object Notation)</strong>, data is represented as a string in a specific format that can be easily converted into a JavaScript object for use in your application.
            </p>

            {jsonExamples.map((example, index) => (
                <div key={index}>
                    <h3>{example.title}</h3>
                    <div className="code-block-container">
                        <span className="copy-feedback" onClick={() => handleCopy(index)}>
                            {copiedStates[index] ? 'Copied!' : <i className="fas fa-clone"></i>}
                        </span>
                        <SyntaxHighlighter
                            language="json"
                            style={atomDark}
                            className="code-block"
                        >
                            {example.json}
                        </SyntaxHighlighter>
                    </div>
                    <p>{example.description}</p>
                    <div className="divider"></div> {/* Adding a divider for visual separation */}
                </div>
            ))}

            <h4>The values can be:</h4>
            <ul>
                <li><strong>String:</strong> <code>"title": "Inception"</code></li>
                <li><strong>Number:</strong> <code>"year": 2010</code></li>
                <li><strong>Null:</strong> <code>"director": null</code></li>
                <li>Other valid JSON types like Boolean, Array, or Object</li>
            </ul>
        </div>
    );
};

export default JSONObjects;
