import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../cssfiles/JSONContent.css'; // Importing CSS file

const JSONPerfOpt = () => {
  const examples = [
    {
      title: "1. Minimize JSON Payload Size",
      description: "Remove unnecessary data fields to reduce payload size.",
      code: `const user = {
    "id": 1001,
    "name": "Alice Johnson",
    "isActive": true
};`,
      output: `{
  "id": 1001,
  "name": "Alice Johnson",
  "isActive": true
}`,
      explanation: "By including only essential fields, you reduce the size of the JSON data, improving transmission speed."
    },
    {
      title: "2. Use Short Keys",
      description: "Reduce key length to minimize payload size, especially for large datasets.",
      code: `const product = {
    "id": 1002,
    "n": "Laptop",
    "p": 599.99
};`,
      output: `{
  "id": 1002,
  "n": "Laptop",
  "p": 599.99
}`,
      explanation: "Using shorter keys decreases JSON size, reducing bandwidth requirements without losing essential information."
    },
    {
      title: "3. Compress JSON Data",
      description: "Use GZIP compression to reduce the size of JSON responses.",
      code: `// Compression done at the server-side for JSON data
const data = {
    "response": "Compressed data is significantly smaller."
};`,
      output: `Compressed JSON payload sent from the server`,
      explanation: "Applying GZIP compression reduces JSON data size for faster client-server communication."
    },
    {
      title: "4. Avoid Deeply Nested Structures",
      description: "Flatten structures when possible to avoid performance overhead from deeply nested objects.",
      code: `const flattenedData = {
    "user_id": 1003,
    "order_id": 789,
    "product_name": "Tablet"
};`,
      output: `{
  "user_id": 1003,
  "order_id": 789,
  "product_name": "Tablet"
}`,
      explanation: "Flattened data structures are faster to parse and traverse, improving JSON processing speed."
    },
    {
      title: "5. Cache JSON Responses",
      description: "Store frequently accessed JSON data in cache to reduce server requests.",
      code: `// Cached JSON response for quick retrieval
const cachedUserData = {
    "userId": 1004,
    "username": "quickAccess"
};`,
      output: `{
  "userId": 1004,
  "username": "quickAccess"
}`,
      explanation: "Caching avoids repeated network calls, resulting in faster load times for cached content."
    },
    {
      title: "6. Use JSON Schema Validation",
      description: "Validate JSON data on the client side to reduce unnecessary server-side processing.",
      code: `const productSchema = {
    "type": "object",
    "properties": {
        "id": { "type": "integer" },
        "name": { "type": "string" },
        "price": { "type": "number" }
    },
    "required": ["id", "name", "price"]
};`,
      output: `{
  "type": "object",
  "properties": {
    "id": { "type": "integer" },
    "name": { "type": "string" },
    "price": { "type": "number" }
  },
  "required": ["id", "name", "price"]
}`,
      explanation: "Using JSON Schema validates data consistency, reducing error rates and enhancing performance."
    },
    {
      title: "7. Use Streaming for Large JSON Files",
      description: "Process large JSON data in chunks rather than loading the entire file at once.",
      code: `// Example: Using JSON streaming in Node.js with streams
const fs = require('fs');
const readline = require('readline');

const readStream = fs.createReadStream('largeFile.json');
const rl = readline.createInterface({ input: readStream });
rl.on('line', (line) => console.log(JSON.parse(line)));`,
      output: `JSON lines streamed from largeFile.json`,
      explanation: "Streaming large JSON files reduces memory load and improves performance for large data sets."
    },
    {
      title: "8. Avoid Circular References",
      description: "Ensure there are no circular references, as JSON.stringify() cannot handle them.",
      code: `const parent = { "name": "Parent" };
const child = { "name": "Child", "parent": parent };
parent.child = child; // Creates a circular reference

// JSON.stringify(parent) would throw an error due to circular reference`,
      output: `Error: Converting circular structure to JSON`,
      explanation: "Avoiding circular references prevents serialization errors and improves JSON reliability."
    }
  ];

  const [copied, setCopied] = useState(false);

  const handleCopy = (code) => {
    navigator.clipboard.writeText(code).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className="json-introduction-container">
      <h2>JSON Performance Optimization</h2>
      <p>Follow these JSON optimization practices to improve data transfer and processing efficiency.</p>
      {examples.map((example, index) => (
        <section key={index}>
          <h3>{example.title}</h3>
          <p><strong>{example.description}</strong></p>
          <div className="code-block-container">
            <span className="copy-feedback" onClick={() => handleCopy(example.code)}>
              {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
            </span>
            <SyntaxHighlighter language="javascript" style={atomDark} className="code-block">
              {example.code}
            </SyntaxHighlighter>
          </div>
          <p><strong>Output:</strong></p>
          <pre className="output-block">{example.output}</pre>
          <p><strong>Explanation:</strong> {example.explanation}</p>
          <hr className="divider" />
        </section>
      ))}
    </div>
  );
};

export default JSONPerfOpt;
