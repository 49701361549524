import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../cssfiles/JSONContent.css'; // Importing CSS file

const JSONWithPython = () => {
  const animalExample = `import json

animal = {
    "species": "Dog",
    "name": "Buddy",
    "age": 5,
    "traits": ["loyal", "playful", "friendly"]
}

animal_json_string = json.dumps(animal)
print(animal_json_string)  # Output: {"species": "Dog", "name": "Buddy", "age": 5, "traits": ["loyal", "playful", "friendly"]}`;

  const movieExample = `import json

movie = {
    "title": "Inception",
    "director": "Christopher Nolan",
    "year": 2010,
    "genres": ["Action", "Sci-Fi", "Thriller"]
}

movie_json_string = json.dumps(movie)
print("Movie JSON String:", movie_json_string)  # Output: Movie JSON String: {"title": "Inception", "director": "Christopher Nolan", "year": 2010, "genres": ["Action", "Sci-Fi", "Thriller"]}

movie_data = '{"title": "Inception", "director": "Christopher Nolan"}'
parsed_movie = json.loads(movie_data)
print("Parsed Movie Object:", parsed_movie)  # Output: Parsed Movie Object: {'title': 'Inception', 'director': 'Christopher Nolan'}`;

  const cityExample = `import json

city = {
    "name": "New York",
    "population": 8419600,
    "country": "USA",
    "landmarks": ["Statue of Liberty", "Central Park", "Times Square"]
}

city_json_string = json.dumps(city)
print("City JSON String:", city_json_string)  # Output: City JSON String: {"name": "New York", "population": 8419600, "country": "USA", "landmarks": ["Statue of Liberty", "Central Park", "Times Square"]}

city_data = '{"name": "New York", "population": 8419600}'
parsed_city = json.loads(city_data)
print("Parsed City Object:", parsed_city)  # Output: Parsed City Object: {'name': 'New York', 'population': 8419600}`;

  const [copied, setCopied] = useState('');

  // Function to copy JSON content to clipboard
  const handleCopy = (example) => {
    navigator.clipboard.writeText(example).then(() => {
      setCopied('Copied!'); // Set copied state to display feedback
      setTimeout(() => setCopied(''), 2000); // Reset copied state after 2 seconds
    });
  };

  return (
    
    <div className="json-examples-container">
         <h2>JSON with Python</h2>
     <p>JSON (JavaScript Object Notation) is often used with Python to handle data. Here's a brief overview with examples </p>
     <p><strong>json.dumps</strong> converts a Python dictionary into a JSON string, allowing for easy storage or transmission. <strong>json.loads</strong> converts a JSON string back into a Python dictionary.</p>
      <section>
        <h2>Animal Example</h2>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(animalExample)}>
            {copied === 'Copied!' ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="python" style={atomDark} className="code-block">
            {animalExample}
          </SyntaxHighlighter>
        </div>
        <p>This example creates a JSON object representing an animal with properties such as species, name, age, and traits. It then converts the object to a JSON string and prints it to the console.</p>
      </section>
      

      <hr className="divider" />

      <section>
        <h2>Movie Example</h2>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(movieExample)}>
            {copied === 'Copied!' ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="python" style={atomDark} className="code-block">
            {movieExample}
          </SyntaxHighlighter>
        </div>
        <p>This example defines a movie object with properties like title, director, year, and genres. It converts the movie object to a JSON string and demonstrates parsing a JSON string back into an object.</p>
      </section>
      
      <hr className="divider" />

      <section>
        <h2>City Example</h2>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(cityExample)}>
            {copied === 'Copied!' ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="python" style={atomDark} className="code-block">
            {cityExample}
          </SyntaxHighlighter>
        </div>
        <p>This example creates a city object with properties for name, population, country, and landmarks. It converts the city object to a JSON string and demonstrates parsing another JSON string back into an object.</p>
      </section>
        </div>
  );
};

export default JSONWithPython;
