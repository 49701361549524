import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../cssfiles/JSONContent.css'; // Importing CSS file

const JSONWithAjax = () => {
  const initialFetchExample = `fetch('https://jsonplaceholder.typicode.com/users')
    .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    })
    .then(users => {
        console.log(users); // Logs the user data
    })
    .catch(error => console.error('Error fetching users:', error));`;

  const initialXhrExample = `var xhr = new XMLHttpRequest();
xhr.open('GET', 'https://jsonplaceholder.typicode.com/users', true);
xhr.onload = function() {
    if (xhr.status >= 200 && xhr.status < 300) {
        const users = JSON.parse(xhr.responseText);
        console.log(users); // Logs the user data
    } else {
        console.error('Error fetching users:', xhr.statusText);
    }
};
xhr.onerror = function() {
    console.error('Network error');
};
xhr.send();`;

  const [fetchData, setFetchData] = useState(initialFetchExample); // Use state to hold Fetch example
  const [xhrData, setXhrData] = useState(initialXhrExample); // Use state to hold XMLHttpRequest example
  const [copied, setCopied] = useState(false);

  // Function to copy example content to clipboard
  const handleCopy = (data) => {
    navigator.clipboard.writeText(data).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
    });
  };

  return (
    <div className="json-introduction-container">
      <section>
        <h2>What is AJAX?</h2>
        <ul className="info-list">
          <li><strong>AJAX</strong> stands for Asynchronous JavaScript and XML.</li>
          <li><strong>AJAX</strong> allows web applications to send and receive data asynchronously without interfering with the display and behavior of the existing page.</li>
          <li>With AJAX, you can update parts of a web page, retrieve new data from the server, and enhance user experience without reloading the entire page.</li>
        </ul>
      </section>

      <hr className="divider" />

      <section>
        <h3>Common Uses of AJAX:</h3>
        <ul className="info-list">
          <li><strong>Dynamic Content Updates:</strong> Load new content on a webpage without reloading.</li>
          <li><strong>Form Submission:</strong> Submit forms asynchronously for instant feedback.</li>
          <li><strong>Real-Time Data:</strong> Fetch live data like stock prices, weather updates, or social media feeds.</li>
          <li><strong>User Interaction:</strong> Enhance user interfaces with features like autocomplete and live search.</li>
        </ul>
      </section>

      <hr className="divider" />

      <section>
        <h3>AJAX with Fetch API</h3>
        <p>The Fetch API is a modern replacement for XMLHttpRequest, offering a more powerful and flexible feature set for making HTTP requests.</p>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(fetchData)}>
            {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="javascript" style={atomDark} className="code-block">
            {fetchData}
          </SyntaxHighlighter>
        </div>
      </section>

      <section>
        <h3>AJAX with XMLHttpRequest</h3>
        <p>XMLHttpRequest is the older standard for making AJAX requests. It is widely supported but can be more verbose and less intuitive compared to the Fetch API.</p>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(xhrData)}>
            {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="javascript" style={atomDark} className="code-block">
            {xhrData}
          </SyntaxHighlighter>
        </div>
      </section>

      <hr className="divider" />

      <section>
        <h3>Differences Between Fetch API and XMLHttpRequest</h3>
        <table className="info-table">
          <thead>
            <tr>
              <th>Feature</th>
              <th>Fetch API</th>
              <th>XMLHttpRequest</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Syntax</td>
              <td>More concise and user-friendly</td>
              <td>More verbose and complex</td>
            </tr>
            <tr>
              <td>Promises</td>
              <td>Returns a Promise</td>
              <td>Uses callbacks</td>
            </tr>
            <tr>
              <td>Response Handling</td>
              <td>Handles responses as streams</td>
              <td>Requires manual parsing</td>
            </tr>
            <tr>
              <td>Error Handling</td>
              <td>Only rejects on network errors</td>
              <td>Can handle HTTP errors manually</td>
            </tr>
            <tr>
              <td>Support for CORS</td>
              <td>Simplified CORS support</td>
              <td>More complicated CORS handling</td>
            </tr>
            <tr>
              <td>Readability</td>
              <td>Cleaner syntax</td>
              <td>More boilerplate code</td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  );
};

export default JSONWithAjax;
