import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../cssfiles/JSONContent.css'; // Importing CSS file

const JSONWebToken = () => {
  const [copied, setCopied] = useState('');

  const handleCopy = (code) => {
    navigator.clipboard.writeText(code).then(() => {
      setCopied(code); // Set the copied code to show feedback
      setTimeout(() => setCopied(''), 2000); // Clear feedback after 2 seconds
    });
  };

  return (
    <div className="json-introduction-container">
      <h2>JSON Web Token (JWT)</h2>
      <ul>
    <li>Open standard for secure information transmission.</li>
    <li>Transmits data as a JSON object.</li>
    <li>Information is digitally signed for verification.</li>
    <li>Can be signed using:</li>
    <ul>
        <li>A secret (HMAC algorithm).</li>
        <li>A public/private key pair (RSA or ECDSA).</li>
    </ul>
</ul>

      <h3>Structure of JWT</h3>
      <p>A JWT is typically composed of three parts:</p>

      <h4>1. Header</h4>
      <p>Contains metadata about the token, including the type of token (JWT) and the signing algorithm (e.g., HMAC SHA256).</p>
      <div className="code-block-container">
        <span className="copy-feedback" onClick={() => handleCopy(`{
    "alg": "HS256",
    "typ": "JWT"
}`)}>
          {copied === `{
    "alg": "HS256",
    "typ": "JWT"
}` ? 'Copied!' : <i className="fas fa-clone"></i>}
        </span>
        <SyntaxHighlighter language="json" style={atomDark} className="code-block">
          {`{
    "alg": "HS256",
    "typ": "JWT"
}`}
        </SyntaxHighlighter>
      </div>

      <h4>2. Payload</h4>
      <p>Contains the claims or the data being transmitted. Claims are statements about an entity (typically, the user) and additional data. There are three types of claims: registered, public, and private.</p>
      <div className="code-block-container">
        <span className="copy-feedback" onClick={() => handleCopy(`{
    "sub": "1234567890",
    "name": "John Doe",
    "admin": true,
    "iat": 1516239022
}`)}>
          {copied === `{
    "sub": "1234567890",
    "name": "John Doe",
    "admin": true,
    "iat": 1516239022
}` ? 'Copied!' : <i className="fas fa-clone"></i>}
        </span>
        <SyntaxHighlighter language="json" style={atomDark} className="code-block">
          {`{
    "sub": "1234567890",
    "name": "John Doe",
    "admin": true,
    "iat": 1516239022
}`}
        </SyntaxHighlighter>
      </div>

      <h4>3. Signature</h4>
      <p>To create the signature part, you take the encoded header, the encoded payload, a secret, and the algorithm specified in the header, and sign it.</p>
      <div className="code-block-container">
        <span className="copy-feedback" onClick={() => handleCopy(`HMACSHA256(
    base64UrlEncode(header) + "." +
    base64UrlEncode(payload),
    your-256-bit-secret
)`)}>
          {copied === `HMACSHA256(
    base64UrlEncode(header) + "." +
    base64UrlEncode(payload),
    your-256-bit-secret
)` ? 'Copied!' : <i className="fas fa-clone"></i>}
        </span>
        <SyntaxHighlighter language="scss" style={atomDark} className="code-block">
          {`HMACSHA256(
    base64UrlEncode(header) + "." +
    base64UrlEncode(payload),
    your-256-bit-secret
)`}
        </SyntaxHighlighter>
      </div>

      <h3>Complete JWT Example</h3>
      <div className="code-block-container">
        <span className="copy-feedback" onClick={() => handleCopy(`eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwibWFya0VkIjpmYWxzZSwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c`)}>
          {copied === `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwibWFya0VkIjpmYWxzZSwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c` ? 'Copied!' : <i className="fas fa-clone"></i>}
        </span>
        <SyntaxHighlighter language="json" style={atomDark} className="code-block">
          {`eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwibWFya0VkIjpmYWxzZSwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c`}
        </SyntaxHighlighter>
      </div>

      <h3>How JWT Works</h3>
      <p>
        <strong>User Authentication:</strong> When a user logs in, the server authenticates the user’s credentials. If valid, the server generates a JWT and sends it back to the client.
      </p>
      <p>
        <strong>Token Storage:</strong> The client (usually a web application) stores the JWT, typically in local storage or a cookie.
      </p>
      <p>
        <strong>Subsequent Requests:</strong> For every subsequent request to the server, the client sends the JWT in the HTTP Authorization header using the Bearer schema:
      </p>
      <div className="code-block-container">
        <span className="copy-feedback" onClick={() => handleCopy(`Authorization: Bearer <token>`)}>
          {copied === `Authorization: Bearer <token>` ? 'Copied!' : <i className="fas fa-clone"></i>}
        </span>
        <SyntaxHighlighter language="makefile" style={atomDark} className="code-block">
          {`Authorization: Bearer <token>`}
        </SyntaxHighlighter>
      </div>
      <p>
        <strong>Token Verification:</strong> The server receives the token, verifies the signature using the secret or public key, and extracts the payload. If the token is valid, the server processes the request; otherwise, it responds with an error.
      </p>

      <h3>Use Cases for JWT</h3>
      <ul>
        <li><strong>Authentication:</strong> JWTs are widely used for authentication, enabling users to log in once and gain access to various resources without re-authenticating.</li>
        <li><strong>Authorization:</strong> JWTs can be used to determine whether a user has the necessary permissions to perform an action on a resource.</li>
        <li><strong>Data Exchange:</strong> JWTs can securely transmit information between different systems and services, allowing for decentralized and secure data sharing.</li>
      </ul>

      <h3>Example of Using JWT in Node.js</h3>
      <p>Here’s a simple example of how you might use JWT in a Node.js application with the jsonwebtoken library:</p>

      <h4>Install the jsonwebtoken Package</h4>
      <div className="code-block-container">
        <span className="copy-feedback" onClick={() => handleCopy(`npm install jsonwebtoken`)}>
          {copied === `npm install jsonwebtoken` ? 'Copied!' : <i className="fas fa-clone"></i>}
        </span>
        <SyntaxHighlighter language="bash" style={atomDark} className="code-block">
          {`npm install jsonwebtoken`}
        </SyntaxHighlighter>
      </div>

      <h4>Code Example</h4>
      <div className="code-block-container">
        <span className="copy-feedback" onClick={() => handleCopy(`const jwt = require('jsonwebtoken');

const secretKey = 'your-256-bit-secret';

const userData = {
    id: 1,
    username: 'john_doe'
};

const token = jwt.sign(userData, secretKey, { expiresIn: '1h' });
console.log('Generated JWT:', token);

jwt.verify(token, secretKey, (err, decoded) => {
    if (err) {
        console.error('Token verification failed:', err.message);
    } else {
        console.log('Decoded JWT payload:', decoded);
    }
}`)}>
          {copied === `const jwt = require('jsonwebtoken');

const secretKey = 'your-256-bit-secret';

const userData = {
    id: 1,
    username: 'john_doe'
};

const token = jwt.sign(userData, secretKey, { expiresIn: '1h' });
console.log('Generated JWT:', token);

jwt.verify(token, secretKey, (err, decoded) => {
    if (err) {
        console.error('Token verification failed:', err.message);
    } else {
        console.log('Decoded JWT payload:', decoded);
    }
}` ? 'Copied!' : <i className="fas fa-clone"></i>}
        </span>
        <SyntaxHighlighter language="javascript" style={atomDark} className="code-block">
          {`const jwt = require('jsonwebtoken');

const secretKey = 'your-256-bit-secret';

const userData = {
    id: 1,
    username: 'john_doe'
};

const token = jwt.sign(userData, secretKey, { expiresIn: '1h' });
console.log('Generated JWT:', token);

jwt.verify(token, secretKey, (err, decoded) => {
    if (err) {
        console.error('Token verification failed:', err.message);
    } else {
        console.log('Decoded JWT payload:', decoded);
    }
}`}
        </SyntaxHighlighter>
      </div>
    </div>
  );
};

export default JSONWebToken;
