// src/components/mainsites/Search.js
import React from 'react';
import './Search.css'; // Import the corresponding CSS file
import image from '../../images/young-smiling-1.jpg'; // Import the image

function Search() {
    return (
        <div className="home-container">
            <div className="search-section">
                <h1>Simple & Quick Learning</h1>
                <p>With the world's largest web developer site.</p>
                <div className="search-container">
                    <input 
                        type="text" 
                        placeholder="Search our tutorials, e.g. JSON" 
                        className="search-box" 
                    />
                    <button className="search-button">Search</button>
                </div>
                <p className="not-sure">Not Sure Where To Begin?</p>
            </div>
            <div className="image-section">
                <img src={image} alt="Girl smiling" className="home-image" />
            </div>
        </div>
    );
}

export default Search;
