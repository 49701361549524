import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../cssfiles/JSONContent.css'; // Importing CSS file

const JSONDatabase = () => {
  const jsonOverview = `Using JSON with databases involves storing and manipulating data in JSON format within a database management system. Many modern databases support JSON as a data type, allowing for flexible data structures and easier integration with applications that communicate using JSON. Here's an overview of how JSON is used with different types of databases.`;

  const noSqlExample = `{
      "_id": "1",
      "name": "Alice",
      "age": 30,
      "email": "alice@example.com"
  }`;

  const noSqlQuery = `db.users.find({ "name": "Alice" });`;

  const relationalDbCreateTable = `CREATE TABLE users (
      id SERIAL PRIMARY KEY,
      data JSON
  );`;

  const relationalDbInsertData = `INSERT INTO users (data) VALUES ('{"name": "Alice", "age": 30, "email": "alice@example.com"}');`;

  const relationalDbQueryData = `SELECT data->>'name' AS name FROM users WHERE data->>'age'::int > 25;`;

  const apiPostExample = `POST /api/users
Content-Type: application/json

{
    "name": "Alice",
    "age": 30,
    "email": "alice@example.com"
}`;

  const apiGetExample = `GET /api/users`;

  const [copied, setCopied] = useState('');

  const handleCopy = (codeSnippet) => {
    navigator.clipboard.writeText(codeSnippet).then(() => {
      setCopied(codeSnippet);
      setTimeout(() => setCopied(''), 2000);
    });
  };

  return (
    <div className="json-introduction-container">
      <section>
        <h2>JSON with Database</h2>
        <p>{jsonOverview}</p>
      </section>

      <hr className="divider" />

      <section>
        <h2>NoSQL Databases</h2>
        <p>
          NoSQL databases, such as MongoDB and Couchbase, are designed to handle unstructured data and often use JSON or a JSON-like format (e.g., BSON) for storage.
        </p>
        <h3>Example: MongoDB</h3>
        <p>Storing Data:</p>
        <div className="code-block-container">
          <span
            className="copy-feedback"
            onClick={() => handleCopy(noSqlExample)}
          >
            {copied === noSqlExample ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="json" style={atomDark} className="code-block">
            {noSqlExample}
          </SyntaxHighlighter>
        </div>
        <p>Querying Data:</p>
        <div className="code-block-container">
          <span
            className="copy-feedback"
            onClick={() => handleCopy(noSqlQuery)}
          >
            {copied === noSqlQuery ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="javascript" style={atomDark} className="code-block">
            {noSqlQuery}
          </SyntaxHighlighter>
        </div>
      </section>

      <hr className="divider" />

      <section>
        <h2>Relational Databases</h2>
        <p>
          Many relational databases, like PostgreSQL and MySQL, have introduced support for JSON data types, allowing you to store and query JSON data alongside traditional relational data.
        </p>
        <h3>Example: PostgreSQL</h3>
        <p>Creating a Table with JSON Column:</p>
        <div className="code-block-container">
          <span
            className="copy-feedback"
            onClick={() => handleCopy(relationalDbCreateTable)}
          >
            {copied === relationalDbCreateTable ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="sql" style={atomDark} className="code-block">
            {relationalDbCreateTable}
          </SyntaxHighlighter>
        </div>
        <p>Inserting JSON Data:</p>
        <div className="code-block-container">
          <span
            className="copy-feedback"
            onClick={() => handleCopy(relationalDbInsertData)}
          >
            {copied === relationalDbInsertData ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="sql" style={atomDark} className="code-block">
            {relationalDbInsertData}
          </SyntaxHighlighter>
        </div>
        <p>Querying JSON Data:</p>
        <div className="code-block-container">
          <span
            className="copy-feedback"
            onClick={() => handleCopy(relationalDbQueryData)}
          >
            {copied === relationalDbQueryData ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="sql" style={atomDark} className="code-block">
            {relationalDbQueryData}
          </SyntaxHighlighter>
        </div>
      </section>

      <hr className="divider" />

      <section>
        <h2>APIs and Data Exchange</h2>
        <p>
          JSON is commonly used as a format for exchanging data between web applications and databases through APIs. Applications can send JSON data to APIs for storage or retrieval.
        </p>
        <h3>Example: RESTful API with JSON</h3>
        <p>Creating a New User:</p>
        <div className="code-block-container">
          <span
            className="copy-feedback"
            onClick={() => handleCopy(apiPostExample)}
          >
            {copied === apiPostExample ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="http" style={atomDark} className="code-block">
            {apiPostExample}
          </SyntaxHighlighter>
        </div>
        <p>Fetching Users:</p>
        <div className="code-block-container">
          <span
            className="copy-feedback"
            onClick={() => handleCopy(apiGetExample)}
          >
            {copied === apiGetExample ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="http" style={atomDark} className="code-block">
            {apiGetExample}
          </SyntaxHighlighter>
        </div>
      </section>
    </div>
  );
};

export default JSONDatabase;
