import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../cssfiles/JSONContent.css'; // Importing CSS file

const JSONConfigurationSettings = () => {
  const jsonConfigExample = `{
    "appName": "My Application",
    "version": "1.0.0",
    "settings": {
      "theme": "dark",
      "language": "en",
      "notifications": {
        "email": true,
        "sms": false
      }
    },
    "database": {
      "host": "localhost",
      "port": 27017,
      "username": "admin",
      "password": "password"
    },
    "api": {
      "baseUrl": "https://api.example.com",
      "timeout": 5000
    }
  }`;

  const [copied, setCopied] = useState(false);

  const handleCopy = (codeSnippet) => {
    navigator.clipboard.writeText(codeSnippet).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className="json-introduction-container">
      <section>
        <h2>What are JSON Configuration Settings?</h2>
        <p>JSON configuration settings refer to the use of JSON (JavaScript Object Notation) format to store and manage configuration data for applications and systems. </p>
        <ul>
          <li>They set up rules and options for how an application works.</li>
          <li>They make it easy to change settings without touching the code.</li>
          <li>They help keep settings clear and easy to understand.</li>
          <li>They allow different settings for different environments, like development or production.</li>
        </ul>
      </section>

      <hr className="divider" />

      <section>
        <h2>Example of JSON Configuration Settings</h2>
        <p>The following example shows a typical JSON configuration file used for application settings:</p>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(jsonConfigExample)}>
            {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="json" style={atomDark} className="code-block">
            {jsonConfigExample}
          </SyntaxHighlighter>
        </div>
        <div className="note">
          <p>This JSON configuration contains settings for the application, including:</p>
          <ul>
            <li><strong>appName:</strong> The name of the application.</li>
            <li><strong>version:</strong> The current version of the application.</li>
            <li><strong>settings:</strong> User-specific settings like theme, language, and notification preferences.</li>
            <li><strong>database:</strong> Information needed to connect to the database, such as host, port, username, and password.</li>
            <li><strong>api:</strong> Settings for how the application interacts with APIs, including the base URL and timeout duration for requests.</li>
          </ul>
        </div>
      </section>

      <hr className="divider" />

      <section>
        <h2>Common JSON Configuration File Names by Language</h2>
        <table className="info-table">
          <thead>
            <tr>
              <th>Language/Framework</th>
              <th>Common Configuration File Names</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>JavaScript/Node.js</td>
              <td>config.json, settings.json</td>
            </tr>
            <tr>
              <td>Python</td>
              <td>config.json, settings.json</td>
            </tr>
            <tr>
              <td>Java</td>
              <td>application.json, config.json</td>
            </tr>
            <tr>
              <td>Ruby</td>
              <td>config.json, settings.json</td>
            </tr>
            <tr>
              <td>Go</td>
              <td>config.json, app_config.json</td>
            </tr>
            <tr>
              <td>PHP</td>
              <td>config.json, settings.json</td>
            </tr>
            <tr>
              <td>.NET (C#)</td>
              <td>appsettings.json</td>
            </tr>
            <tr>
              <td>Angular</td>
              <td>environment.json, config.json</td>
            </tr>
            <tr>
              <td>React</td>
              <td>config.json, settings.json</td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  );
};

export default JSONConfigurationSettings;
