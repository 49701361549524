import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../cssfiles/JSONContent.css'; // Importing CSS file

const JSONWithRuby = () => {
  // Sample Ruby code snippets for demonstration
  const rubyCarExample = `# Car class definition
class Car
  attr_accessor :make, :model, :year

  def initialize(make, model, year)
    @make = make
    @model = model
    @year = year
  end
end

car = Car.new("Tesla", "Model S", 2022)
car_json = car.to_json
puts car_json
# Output: {"make":"Tesla","model":"Model S","year":2022}
`;

  const rubyProductExample = `# Product hash example
product = {
  name: "Laptop",
  brand: "Dell",
  price: 999.99
}

product_json = product.to_json
puts product_json
# Output: {"name":"Laptop","brand":"Dell","price":999.99}
`;

  const rubyStudentExample = `# Student class example
class Student
  attr_accessor :name, :age, :grades

  def initialize(name, age, grades)
    @name = name
    @age = age
    @grades = grades
  end
end

student = Student.new("Alice", 22, { math: "A", science: "B" })
student_json = student.to_json
puts student_json
# Output: {"name":"Alice","age":22,"grades":{"math":"A","science":"B"}}
`;

  const [copied, setCopied] = useState(false);

  const handleCopy = (codeSnippet) => {
    navigator.clipboard.writeText(codeSnippet).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className="json-introduction-container">
      <section>
        <h2>Using JSON with Ruby</h2>
        <p>In Ruby, JSON data can be easily handled using the <code>to_json</code> method, which converts Ruby objects to JSON format.</p>
      </section>

      <hr className="divider" />

      <section>
        <h3>Car Example</h3>
        <p><strong>Serialization</strong>: This example demonstrates how to serialize a Ruby object representing a car to JSON format.</p>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(rubyCarExample)}>
            {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="ruby" style={atomDark} className="code-block">
            {rubyCarExample}
          </SyntaxHighlighter>
        </div>
        <p>This code snippet shows how to convert a Car object into a JSON string and print it to the console.</p>
      </section>

      <hr className="divider" />

      <section>
        <h3>Product Example</h3>
        <p><strong>Serialization</strong>: This example demonstrates how to convert a Ruby hash representing a product into JSON format.</p>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(rubyProductExample)}>
            {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="ruby" style={atomDark} className="code-block">
            {rubyProductExample}
          </SyntaxHighlighter>
        </div>
        <p>This code snippet shows how to convert a Ruby hash into a JSON string.</p>
      </section>

      <hr className="divider" />

      <section>
        <h3>Student Example</h3>
        <p><strong>Serialization</strong>: This example illustrates how to serialize a Ruby object representing a student into JSON format.</p>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(rubyStudentExample)}>
            {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="ruby" style={atomDark} className="code-block">
            {rubyStudentExample}
          </SyntaxHighlighter>
        </div>
        <p>This example demonstrates how to convert a Student object into a JSON string.</p>
      </section>
    </div>
  );
};

export default JSONWithRuby;
