import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../cssfiles/JSONContent.css'; // Importing CSS file

const JSONCustomConversions = () => {
  // Example of a complex user object
  const userObject = `const user = {
    name: "Alice",
    age: 30,
    birthdate: new Date(1993, 4, 15), // May 15, 1993
    address: {
        city: "New York",
        country: "USA"
    }
};`;

  // Custom serialization example
  const customSerialization = `user.toJSON = function() {
    return {
        name: this.name,
        age: this.age,
        birthdate: this.birthdate.toISOString(), // Convert date to ISO string
        address: this.address
    };
};

// Convert to JSON
const jsonString = JSON.stringify(user);
console.log(jsonString);`;

  // Custom deserialization example
  const customDeserialization = `function fromJSON(jsonString) {
    const data = JSON.parse(jsonString);
    return {
        ...data,
        birthdate: new Date(data.birthdate) // Convert ISO string back to Date
    };
}

// Convert back from JSON
const userFromJSON = fromJSON(jsonString);
console.log(userFromJSON);`;

  const [copied, setCopied] = useState(false);

  const handleCopy = (codeSnippet) => {
    navigator.clipboard.writeText(codeSnippet).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className="json-introduction-container">
      <section>
        <h2>JSON Custom Conversions</h2>
        <ul>
          <li>Custom conversions in JSON refer to the process of transforming complex data structures into a JSON format suitable for storage or transmission.</li>
          <li>Involves customizing how certain types or structures are serialized (converted to JSON) or deserialized (converted from JSON back to an object).</li>
          <li>These conversions help meet specific application needs.</li>
        </ul>
      </section>

      <hr className="divider" />

      <section>
        <h3>Example: Custom Conversions in JavaScript</h3>
        <p>Let’s look at a simple example using JavaScript, where we have a complex object that we want to convert to JSON and back.</p>

        <h4>1. Define a Complex Object</h4>
        <p>This code defines a user object that includes properties like name, age, birthdate (as a Date object), and an address object.</p>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(userObject)}>
            {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="javascript" style={atomDark} className="code-block">
            {userObject}
          </SyntaxHighlighter>
        </div>

        <h4>2. Custom Serialization</h4>
        <p>This code implements a custom <code>toJSON</code> method for the user object, which formats the object for JSON serialization by converting the birthdate to an ISO string.</p>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(customSerialization)}>
            {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="javascript" style={atomDark} className="code-block">
            {customSerialization}
          </SyntaxHighlighter>
        </div>

        <h4>3. Custom Deserialization</h4>
        <p>This function reconstructs the user object from a JSON string, converting the ISO date string back into a Date object.</p>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(customDeserialization)}>
            {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="javascript" style={atomDark} className="code-block">
            {customDeserialization}
          </SyntaxHighlighter>
        </div>
      </section>
    </div>
  );
};

export default JSONCustomConversions;
