import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../cssfiles/JSONContent.css'; // Importing CSS file

const JSONMinify = () => {
  const normalJsonExample = `{
    "name": "Alice",
    "age": 30,
    "email": "alice@example.com",
    "preferences": {
      "theme": "dark",
      "notifications": true
    }
  }`;

  const minifiedJsonExample = `{"name":"Alice","age":30,"email":"alice@example.com","preferences":{"theme":"dark","notifications":true}}`;

  const [copied, setCopied] = useState(false);

  const handleCopy = (codeSnippet) => {
    navigator.clipboard.writeText(codeSnippet).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className="json-introduction-container">
      <section>
        <h2>What is JSON Minification?</h2>
        <p>
          JSON Minification is the process of removing unnecessary characters 
          (such as whitespaces and new lines) from JSON data without affecting 
          its structure. Minified JSON is more compact, which helps in reducing 
          file size and loading time in web applications.
        </p>
      </section>

      <hr className="divider" />

      <section>
        <h2>An Example of Minified JSON</h2>
        <p>Have a look below the normal JSON file and its minified version:</p>
        
        <h3>Normal JSON</h3>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(normalJsonExample)}>
            {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="json" style={atomDark} className="code-block">
            {normalJsonExample}
          </SyntaxHighlighter>
        </div>

        <h3>Minified JSON</h3>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(minifiedJsonExample)}>
            {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="json" style={atomDark} className="code-block">
            {minifiedJsonExample}
          </SyntaxHighlighter>
        </div>
        
        <p>As shown, the minified JSON removes all whitespaces and line breaks, making the file smaller and faster to load.</p>
      </section>

      <hr className="divider" />

      <section>
        <h2>Popular JSON Minification Tools</h2>
        <ul>
          <li>
            <strong><a href="https://www.jsonminify.com" target="_blank" rel="noopener noreferrer">JSONMinify</a></strong>: A straightforward tool for minifying JSON data.
          </li>
          <li>
            <strong><a href="https://codebeautify.org/jsonminifier" target="_blank" rel="noopener noreferrer">Code Beautify JSON Minifier</a></strong>: An online tool to minify JSON data and reduce file size.
          </li>
          <li>
            <strong><a href="https://jsonformatter.org/json-minify" target="_blank" rel="noopener noreferrer">JSON Formatter</a></strong>: Offers both JSON formatting and minification.
          </li>

        </ul>
      </section>

      <hr className="divider" />

      <section>
        <h2>Benefits of JSON Minification</h2>
        <ul>
          <li>✅ <strong>Reduced File Size:</strong> Minification removes extra spaces and newlines, making JSON files smaller.</li>
          <li>✅ <strong>Faster Data Transfer:</strong> Smaller files result in faster transmission across networks.</li>
          <li>✅ <strong>Improved Page Load Speed:</strong> Reduced file sizes lead to faster loading of resources on web pages.</li>
          <li>✅ <strong>Optimized Storage:</strong> Minimizing data size conserves storage space, especially for large datasets.</li>
        </ul>
      </section>
    </div>
  );
};

export default JSONMinify;
