import React, { useEffect, useState } from 'react';
import '../cssfiles/RightSidebar.css';

function RightSidebar() {
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000); // Update every second
        return () => clearInterval(timer);
    }, []);

    const tips = [
        "JSON is a lightweight data-interchange format.",
        "Use double quotes for keys and string values in JSON.",
        "Avoid comments in JSON; they’re not officially supported.",
        "JSON is language-independent, making it widely applicable.",
        "JSON only supports text data. Binary data requires encoding.",
        "Use JSON.stringify() to convert JavaScript objects to JSON.",
        "Use JSON.parse() to convert JSON strings to JavaScript objects.",
        "JSON keys must be unique within an object.",
        "JSON does not allow trailing commas in arrays or objects.",
        "Use JSON for quick and easy data sharing between web apps.",
        "A JSON array can contain multiple data types.",
        "JSON is commonly used with REST APIs for data exchange.",
        "You can validate JSON with a JSON Schema validator.",
        "Properly format JSON to improve readability and debugging.",
        "JSON supports nested objects for complex data structures.",
        "Use tools like Postman to test JSON APIs efficiently.",
        "Minify JSON to reduce size for faster transmission.",
        "Be mindful of deeply nested JSON, as it can slow parsing.",
        "Use JSON linters to ensure clean and error-free data.",
        "You can beautify JSON for easier reading during debugging."
    ];

    const [currentTipIndex, setCurrentTipIndex] = useState(0);

    useEffect(() => {
        const tipInterval = setInterval(() => {
            setCurrentTipIndex((prevIndex) => (prevIndex + 1) % tips.length);
        }, 30000); // Change tip every 30 seconds
        return () => clearInterval(tipInterval);
    }, [tips.length]);

    return (
        <div className="right-sidebar">
            {/* Digital Clock Display */}
            <h2>🕒 Current Time</h2>
            <div className="digital-clock">
                {currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false })}
            </div>

            {/* Tools Section */}
            <h2>🛠️ My Tools</h2>
            <p>Useful resources for developers:</p>
            <ul>
                <li><a href="https://jsonvalidator.quicklearn.dev/">JSON Validator</a></li>
                <li><a href="https://whatismyip.quicklearn.dev/">What is My IP</a></li>
                <li><a href="https://htmlcss-editor.quicklearn.dev/">HTML & CSS Editor</a></li>
            </ul>

            {/* Tutorials Section */}
            <h2>📘 Upcoming Tutorials</h2>
            <p>We are working on new tutorials, stay tuned!</p>

            {/* JSON Tips Section */}
            <h2>💡 JSON Tips</h2>
            <div className="tip-container">
                <p>Tip: {tips[currentTipIndex]}</p>
            </div>
        </div>
    );
}

export default RightSidebar;
