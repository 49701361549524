import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../cssfiles/JSONContent.css'; // Importing CSS file

const JSONWithPHP = () => {
  // Sample PHP code snippets for demonstration
  const phpUserExample = `<?php
class User {
    public $username;
    public $age;
    public $isVerified;

    function __construct($username, $age, $isVerified) {
        $this->username = $username;
        $this->age = $age;
        $this->isVerified = $isVerified;
    }
}

$user = new User("john_doe", 30, true);
$userJson = json_encode($user);
echo $userJson;
// Output: {"username":"john_doe","age":30,"isVerified":true}
?>`;

  const phpBookExample = `<?php
$book = [
    "title" => "1984",
    "author" => "George Orwell",
    "published" => 1949
];

$bookJson = json_encode($book);
echo $bookJson;
// Output: {"title":"1984","author":"George Orwell","published":1949}
?>`;

  const phpMovieExample = `<?php
$movie = [
    "title" => "Inception",
    "director" => "Christopher Nolan",
    "releaseYear" => 2010
];

$movieJson = json_encode($movie);
echo $movieJson;
// Output: {"title":"Inception","director":"Christopher Nolan","releaseYear":2010}
?>`;

  const [copied, setCopied] = useState(false);

  const handleCopy = (codeSnippet) => {
    navigator.clipboard.writeText(codeSnippet).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className="json-introduction-container">
      <section>
        <h2>Using JSON with PHP</h2>
        <p>In PHP, JSON data can be easily encoded and decoded using the <code>json_encode</code> and <code>json_decode</code> functions, facilitating the conversion between PHP arrays/objects and JSON format.</p>
      </section>

      <hr className="divider" />

      <section>
        <h3>User Example</h3>
        <p><strong>Serialization</strong>: This example demonstrates how to serialize a PHP object to JSON format.</p>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(phpUserExample)}>
            {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="php" style={atomDark} className="code-block">
            {phpUserExample}
          </SyntaxHighlighter>
        </div>
        <p>This code snippet shows how to convert a User object into a JSON string and print it to the console.</p>
      </section>

      <hr className="divider" />

      <section>
        <h3>Book Example</h3>
        <p><strong>Serialization</strong>: This example demonstrates how to convert a PHP array representing a book into JSON format.</p>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(phpBookExample)}>
            {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="php" style={atomDark} className="code-block">
            {phpBookExample}
          </SyntaxHighlighter>
        </div>
        <p>This code snippet shows how to convert a PHP associative array into a JSON string.</p>
      </section>

      <hr className="divider" />

      <section>
        <h3>Movie Example</h3>
        <p><strong>Serialization</strong>: This example illustrates how to serialize a PHP array representing a movie into JSON format.</p>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(phpMovieExample)}>
            {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="php" style={atomDark} className="code-block">
            {phpMovieExample}
          </SyntaxHighlighter>
        </div>
        <p>This example demonstrates how to convert a PHP associative array for a movie into a JSON string.</p>
      </section>
    </div>
  );
};

export default JSONWithPHP;
