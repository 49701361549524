// src/components/mainsites/Header.js
import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for routing
import './Header.css'; // Import the corresponding CSS file

function Header() {
    return (
        <header>
            <nav>
                <ul className="navbar">
                    <li><Link to="/json/introduction">JSON</Link></li>
                    <li><Link to="/json/introduction">HTML</Link></li>
                    <li><Link to="/json/introduction">CSS</Link></li>
                    <li><Link to="/json/introduction">JavaScript</Link></li>
                    <li><Link to="/json/introduction">React</Link></li>
                    <li><Link to="/json/introduction">SQL</Link></li>
                    <li><Link to="/json/introduction">Python</Link></li>
                    <li><Link to="/json/introduction">Java</Link></li>
                    <li><Link to="/json/introduction">PHP</Link></li>
                    <li><Link to="/json/introduction">C++</Link></li>
                    <li><Link to="/json/introduction">MySQL</Link></li>
                    <li><Link to="/json/introduction">Node.js</Link></li>
                    <li><Link to="/json/introduction">Golang</Link></li>
                </ul>
            </nav>
        </header>
    );
}

export default Header;
