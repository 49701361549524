import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../cssfiles/JSONContent.css'; // Importing CSS file

const JSONExamples = () => {
  const bookExample = `const book = {
    "title": "The Great Gatsby",
    "author": "F. Scott Fitzgerald"
};

const bookJsonString = JSON.stringify(book);
console.log(bookJsonString); // {"title":"The Great Gatsby","author":"F. Scott Fitzgerald"}`;

  const carExample = `const car = {
    "make": "Toyota",
    "model": "Corolla",
    "year": 2020,
    "features": ["Air Conditioning", "Navigation System", "Bluetooth"]
};

const carJsonString = JSON.stringify(car);
console.log("Car JSON String:", carJsonString); // Car JSON String: {"make":"Toyota","model":"Corolla","year":2020,"features":["Air Conditioning","Navigation System","Bluetooth"]}

const carData = '{"make": "Toyota", "model": "Corolla"}';
const parsedCar = JSON.parse(carData);
console.log("Parsed Car Object:", parsedCar); // Parsed Car Object: { make: 'Toyota', model: 'Corolla' }`;

  const studentExample = `const student = {
    "name": "Ananya Rao",
    "age": 21,
    "major": "Computer Science",
    "grades": {
      "math": "A",
      "science": "B+",
      "history": "A-"
    },
    "enrolled": true
};

const studentJsonString = JSON.stringify(student);
console.log("Student JSON String:", studentJsonString); // Student JSON String: {"name":"Ananya Rao","age":21,"major":"Computer Science","grades":{"math":"A","science":"B+","history":"A-"},"enrolled":true}

const studentData = '{"name": "Ananya Rao", "age": 21}';
const parsedStudent = JSON.parse(studentData);
console.log("Parsed Student Object:", parsedStudent); // Parsed Student Object: { name: 'Ananya Rao', age: 21 }`;

  const [copied, setCopied] = useState('');

  // Function to copy JSON content to clipboard
  const handleCopy = (example) => {
    navigator.clipboard.writeText(example).then(() => {
      setCopied('Copied!'); // Set copied state to display feedback
      setTimeout(() => setCopied(''), 2000); // Reset copied state after 2 seconds
    });
  };

  return (
    
    <div className="json-examples-container">
     <h2>JSON with JavaScript</h2>
     <p>JSON is commonly used to structure data for storage, transfer, and API responses. JavaScript provides two main methods, JSON.stringify() and JSON.parse(): </p>
     <p><strong>JSON.stringify():</strong> Converts a JavaScript object into a JSON string.</p>
     <p> <strong>JSON.parse(): </strong> Converts a JSON string back into a JavaScript object.</p>
      <section>
        <h2>Book Example</h2>
        
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(bookExample)}>
            {copied === 'Copied!' ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="javascript" style={atomDark} className="code-block">
            {bookExample}
          </SyntaxHighlighter>
        </div>
        <p>This example creates a JSON object representing a book with a title and author. It then converts the object to a JSON string and logs it to the console.</p>
      </section>

      <hr className="divider" />

      <section>
        <h2>Car Example</h2>
        <p><strong>JSON.stringify</strong> converts a JavaScript object into a JSON string, allowing for easy data storage or transfer. <strong>JSON.parse</strong> parses a JSON string back into a JavaScript object for use in your application.</p>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(carExample)}>
            {copied === 'Copied!' ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="javascript" style={atomDark} className="code-block">
            {carExample}
          </SyntaxHighlighter>
        </div>
        <p>This example defines a car object with properties like make, model, year, and features. It converts the car object to a JSON string and demonstrates parsing a JSON string back into an object.</p>
      </section>

      <hr className="divider" />

      <section>
        <h3>Student Example</h3>
        <p><strong>JSON.stringify</strong> is used to convert JavaScript objects into JSON strings for storage or transmission. <strong>JSON.parse</strong> allows you to retrieve and use data from JSON strings by converting them back into JavaScript objects.</p>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(studentExample)}>
            {copied === 'Copied!' ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="javascript" style={atomDark} className="code-block">
            {studentExample}
          </SyntaxHighlighter>
        </div>
        <p>This example creates a student object with properties for name, age, major, grades, and enrollment status. It converts the student object to a JSON string and demonstrates parsing another JSON string back into an object.</p>
      </section>
    </div>
  );
};

export default JSONExamples;
