// src/components/JSONvsXML.js
import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../cssfiles/JSONContent.css'; // Importing CSS file

const JSONvsXML = () => {
  const jsonExample = `{
  "library": [
    { "title": "1984", "author": "George Orwell" },
    { "title": "To Kill a Mockingbird", "author": "Harper Lee" },
    { "title": "The Great Gatsby", "author": "F. Scott Fitzgerald" }
  ]
}`;

  const xmlExample = `<library>
  <book>
    <title>1984</title>
    <author>George Orwell</author>
  </book>
  <book>
    <title>To Kill a Mockingbird</title>
    <author>Harper Lee</author>
  </book>
  <book>
    <title>The Great Gatsby</title>
    <author>F. Scott Fitzgerald</author>
  </book>
</library>`;

  const [copiedJson, setCopiedJson] = useState(false);
  const [copiedXml, setCopiedXml] = useState(false);

  const handleCopy = (content, type) => {
    navigator.clipboard.writeText(content).then(() => {
      if (type === 'json') {
        setCopiedJson(true);
        setTimeout(() => setCopiedJson(false), 2000);
      } else if (type === 'xml') {
        setCopiedXml(true);
        setTimeout(() => setCopiedXml(false), 2000);
      }
    });
  };

  return (
    
    

    <div className="json-introduction-container">
         <h2>JSON vs XML</h2>
      <p>Both JSON (JavaScript Object Notation) and XML (eXtensible Markup Language) are formats used to transmit data from a web server.</p>

      <section>
        <h3>JSON Example</h3>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(jsonExample, 'json')}>
            {copiedJson ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="json" style={atomDark} className="code-block">
            {jsonExample}
          </SyntaxHighlighter>
        </div>
      </section>
      <hr className="divider" />

      <section>
        <h3>XML Example</h3>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(xmlExample, 'xml')}>
            {copiedXml ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="xml" style={atomDark} className="code-block">
            {xmlExample}
          </SyntaxHighlighter>
        </div>
      </section>

      <section>
      <hr className="divider" />

        <h3>Similarities Between JSON and XML</h3>
        <ul className="info-list">
          <li>Self-Describing: Both formats are human-readable, making them easier to understand.</li>
          <li>Hierarchical Structure: They both allow for nested values, meaning you can have values within values.</li>
          <li>Cross-Platform Usability: Many programming languages can parse both JSON and XML data.</li>
          <li>Data Retrieval: Both formats can be retrieved using XMLHttpRequest in web applications.</li>
        </ul>
      </section>

      <hr className="divider" />

      <section>
        <h3>Differences Between JSON and XML</h3>
        <ul className="info-list">
          <li>No Closing Tags: JSON does not require closing tags like XML does.</li>
          <li>Conciseness: JSON is generally more compact than XML, leading to smaller file sizes.</li>
          <li>Ease of Reading and Writing: JSON’s simpler syntax makes it quicker to read and write.</li>
          <li>Support for Arrays: JSON supports array structures, which allow for more straightforward data representation.</li>
        </ul>
      </section>

      <hr className="divider" />

      <section>
        <h3>Key Distinction</h3>
        <p>The most significant difference is in how the two formats are parsed:</p>
        <ul className="info-list">
          <li>XML requires a specific XML parser to read and manipulate the data.</li>
          <li>JSON can be easily parsed into a usable JavaScript object using the JSON.parse() function.</li>
        </ul>
      </section>

      <hr className="divider" />

      <section>
        <h3>Why JSON is Preferred Over XML</h3>
        <ul className="info-list">
          <li><strong>Simplicity:</strong> JSON is easier to parse compared to XML.</li>
          <li><strong>Direct Use in JavaScript:</strong> When you parse JSON, it converts into a JavaScript object that can be used immediately.</li>
          <li><strong>For AJAX applications:</strong> JSON is often faster and more straightforward than XML:
            <ul>
              <li>Using XML:
                <ol>
                  <li>Retrieve an XML document.</li>
                  <li>Utilize the XML DOM to traverse the document.</li>
                  <li>Extract values and assign them to variables.</li>
                </ol>
              </li>
              <li>Using JSON:
                <ol>
                  <li>Fetch a JSON string.</li>
                  <li>Use JSON.parse() to convert the string into a JavaScript object.</li>
                </ol>
              </li>
            </ul>
          </li>
        </ul>
      </section>

      <hr className="divider" />


    </div>
  );
};

export default JSONvsXML;
