import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../cssfiles/JSONContent.css'; // Importing CSS file

const JSONIntroduction = () => {
  const initialJsonExample = `{
    "name": "Alice Smith",
    "age": 28,
    "isStudent": true,
    "car": null
  }`;

  const [jsonData, setJsonData] = useState(initialJsonExample); // Use state to hold JSON data
  const [copied, setCopied] = useState(false);

  // Function to copy JSON content to clipboard
  const handleCopy = () => {
    navigator.clipboard.writeText(jsonData).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
    });
  };

  // Function to validate JSON by redirecting to the validation site
  const handleValidateJson = () => {
    const encodedJson = encodeURIComponent(jsonData); // Encode JSON for URL
    const validatorUrl = `https://jsonvalidator.quicklearn.dev/?json=${encodedJson}`; // Construct the URL for your validator
    window.open(validatorUrl, '_blank'); // Open the URL in a new tab
  };

  return (
    <div className="json-introduction-container">
      <section>
        <h2>What is JSON?</h2>
        <ul className="info-list">
          <li><strong>JSON</strong> stands for JavaScript Object Notation.</li>
          <li><strong>JSON</strong> is a lightweight data-interchange format.</li>
          <li><strong>JSON</strong> is plain text written in JavaScript object notation.</li>
          <li><strong>JSON</strong> is used to send data between computers.</li>
          <li><strong>JSON</strong> is language independent.</li>
        </ul>
      </section>

      <hr className="divider" />

      <section>
        <h3>Example:</h3>
        <div className="code-block-container">
          {/* Copy icon with feedback text */}
          <span className="copy-feedback" onClick={handleCopy}>
            {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="json" style={atomDark} className="code-block">
            {jsonData}
          </SyntaxHighlighter>
        </div>
        <button className="validate-button" onClick={handleValidateJson}>
          Validate JSON
        </button>
      </section>

      <section>
        <h3>JSON Structure Explanation</h3>
        <ul className="info-list">
          <li><strong>Curly Braces { }</strong>: These indicate that we are defining an object.</li>
          <li>
            <strong>Key-Value Pairs</strong>: Each entry inside the JSON object consists of a key and a value:
            <ul>
              <li><code>"name": "Alice"</code>: The key is <code>"name"</code> and its value is the string <code>"Alice"</code>.</li>
              <li><code>"age": 28</code>: The key is <code>"age"</code> and its value is the number <code>28</code>.</li>
              <li><code>"car": null</code>: The key is <code>"car"</code> and its value is <code>null</code>, indicating that there is no value assigned to this key.</li>
            </ul>
          </li>
        </ul>
      </section>

      <hr className="divider" />

      <section>
        <h2>Why Use JSON?</h2>
        <ul className="info-list">
          <li><strong>Human-Readable:</strong> Its syntax is easy to understand and read, making it user-friendly for developers.</li>
          <li><strong>Language Independent:</strong> JSON is supported by most programming languages, allowing for cross-platform data exchange.</li>
          <li><strong>Lightweight:</strong> It has a minimalistic syntax that reduces data transmission size compared to other formats like XML.</li>
          <li><strong>Structured Data:</strong> JSON allows for nested data structures, making it suitable for representing complex data models.</li>
        </ul>
      </section>

      <hr className="divider" />

      <section>
        <h2>History of JSON</h2>
        <ul className="info-list">
          <li><strong>Origins:</strong> JSON was derived from JavaScript in the early 2000s, primarily for transmitting data between a server and web applications.</li>
          <li><strong>Standardization:</strong> In 2013, JSON was standardized as ECMA-404, and its specification was published by the Internet Engineering Task Force (IETF) as RFC 7159.</li>
          <li><strong>Widespread Adoption:</strong> JSON has become the de facto standard for APIs and web services, replacing XML in many applications due to its simplicity and efficiency.</li>
        </ul>
      </section>
    </div>
  );
};

export default JSONIntroduction;
