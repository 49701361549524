import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../cssfiles/JSONContent.css'; // Importing CSS file

const JSONWithRestAPI = () => {
  const examples = [
    {
      title: "1. Fetching JSON Data from a REST API",
      description: "You can use the Fetch API to get JSON data from a REST endpoint.",
      code: `fetch('https://jsonplaceholder.typicode.com/posts')
  .then(response => response.json())
  .then(data => console.log(data))
  .catch(error => console.error('Error fetching data:', error));`,
      output: `// Output will be an array of posts from the API`,
      explanation: "This example demonstrates how to fetch JSON data from a public API and log it to the console."
    },
    {
      title: "2. Sending JSON Data to a REST API",
      description: "You can send JSON data using a POST request.",
      code: `const postData = {
  title: 'foo',
  body: 'bar',
  userId: 1
};

fetch('https://jsonplaceholder.typicode.com/posts', {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(postData)
})
.then(response => response.json())
.then(data => console.log('Success:', data))
.catch(error => console.error('Error:', error));`,
      output: `// Output will show the newly created post object`,
      explanation: "This code sends a JSON object to a REST API and logs the response."
    },
    {
      title: "3. Updating JSON Data with PUT Request",
      description: "You can update existing JSON data using a PUT request.",
      code: `const updatedPost = {
  id: 1,
  title: 'Updated Title',
  body: 'Updated Body',
  userId: 1
};

fetch('https://jsonplaceholder.typicode.com/posts/1', {
  method: 'PUT',
  headers: {
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(updatedPost)
})
.then(response => response.json())
.then(data => console.log('Updated:', data))
.catch(error => console.error('Error:', error));`,
      output: `// Output will show the updated post object`,
      explanation: "This example updates a post on the server and logs the updated object."
    },
    {
      title: "4. Deleting JSON Data with DELETE Request",
      description: "You can delete JSON data by sending a DELETE request.",
      code: `fetch('https://jsonplaceholder.typicode.com/posts/1', {
  method: 'DELETE'
})
.then(() => console.log('Post deleted'))
.catch(error => console.error('Error:', error));`,
      output: `// Output will confirm the post deletion`,
      explanation: "This code deletes a post from the server and logs a confirmation message."
    }
  ];

  const [copied, setCopied] = useState(false);

  const handleCopy = (code) => {
    navigator.clipboard.writeText(code).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className="json-introduction-container">
      <h2>JSON with REST API</h2>
      <p>Explore how to handle JSON data with REST APIs through various examples.</p>
      {examples.map((example, index) => (
        <section key={index}>
          <h3>{example.title}</h3>
          <p><strong>{example.description}</strong></p>
          <div className="code-block-container">
            <span className="copy-feedback" onClick={() => handleCopy(example.code)}>
              {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
            </span>
            <SyntaxHighlighter language="javascript" style={atomDark} className="code-block">
              {example.code}
            </SyntaxHighlighter>
          </div>
          <p><strong>Output:</strong></p>
          <pre className="output-block">{example.output}</pre>
          <p><strong>Explanation:</strong> {example.explanation}</p>
          <hr className="divider" />
        </section>
      ))}
    </div>
  );
};

export default JSONWithRestAPI;
