import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../cssfiles/JSONContent.css'; // Importing CSS file

const JSONWithGolang = () => {
  // Sample JSON objects for demonstration
  const golangUserExample = `package main
import (
  "encoding/json"
  "fmt"
)
type User struct {
  Username   string \`json:"username"\`
  Age        int    \`json:"age"\`
  IsVerified bool   \`json:"isVerified"\`
}

func main() {
  user := User{"emma_woodhouse", 26, true}
  userJson, _ := json.Marshal(user)
  fmt.Println(string(userJson))
  // Output: {"username":"emma_woodhouse","age":26,"isVerified":true}
}`;

  const golangProductExample = `package main
import (
  "encoding/json"
  "fmt"
)
type Product struct {
  Name      string  \`json:"product"\`
  Price     float64 \`json:"price"\`
  Available bool    \`json:"available"\`
}

func main() {
  jsonData := []byte(\`{"product":"Laptop","price":1200.50,"available":true}\`)
  var product Product
  json.Unmarshal(jsonData, &product)
  fmt.Printf("Product: %+v\\n", product)
  // Output: Product: {Name:Laptop Price:1200.5 Available:true}
}`;

  const golangBookExample = `package main
import (
  "encoding/json"
  "fmt"
)
type Book struct {
  Title     string \`json:"title"\`
  Author    string \`json:"author"\`
  Published int    \`json:"published"\`
}

func main() {
  book := Book{"1984", "George Orwell", 1949}
  bookJson, _ := json.Marshal(book)
  fmt.Println(string(bookJson))
  // Output: {"title":"1984","author":"George Orwell","published":1949}
}`;

  const golangLocationExample = `package main
import (
  "encoding/json"
  "fmt"
)
type Location struct {
  City    string \`json:"city"\`
  State   string \`json:"state"\`
  Country string \`json:"country"\`
}

func main() {
  jsonData := []byte(\`{"city":"San Francisco","state":"CA","country":"USA"}\`)
  var location Location
  json.Unmarshal(jsonData, &location)
  fmt.Printf("Location: %+v\\n", location)
  // Output: Location: {City:San Francisco State:CA Country:USA}
}`;

  const [jsonData, setJsonData] = useState(golangUserExample);
  const [copied, setCopied] = useState(false);

  const handleCopy = (codeSnippet) => {
    navigator.clipboard.writeText(codeSnippet).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className="json-introduction-container">
      <section>
        <h2>Using JSON with Golang</h2>
        <p>JSON in Go is handled through the <code>encoding/json</code> package, enabling both encoding structs to JSON format and decoding JSON data back to Go structures.</p>
      </section>

      <hr className="divider" />

      <section>
        <h3>User Example</h3>
        <p><strong>JSON Struct Encoding</strong>: Converts Go structs into JSON format for easy data transmission.</p>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(golangUserExample)}>
            {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="go" style={atomDark} className="code-block">
            {golangUserExample}
          </SyntaxHighlighter>
        </div>
        <p>This code snippet shows how a Go struct is marshaled into JSON format.</p>
      </section>

      <hr className="divider" />

      <section>
        <h3>Product Example</h3>
        <p><strong>JSON Decoding</strong>: Converts JSON data into a Go struct for further manipulation.</p>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(golangProductExample)}>
            {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="go" style={atomDark} className="code-block">
            {golangProductExample}
          </SyntaxHighlighter>
        </div>
        <p>This example demonstrates unmarshalling JSON data into a Go struct to access and manipulate the values.</p>
      </section>

      <hr className="divider" />

      <section>
        <h3>Book Example</h3>
        <p><strong>JSON Encoding Example</strong>: Showcasing how to encode a book struct to JSON format.</p>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(golangBookExample)}>
            {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="go" style={atomDark} className="code-block">
            {golangBookExample}
          </SyntaxHighlighter>
        </div>
        <p>This snippet illustrates encoding a book struct into JSON format for storage or transmission.</p>
      </section>

      <hr className="divider" />

      <section>
        <h3>Location Example</h3>
        <p><strong>JSON Decoding Example</strong>: Parsing JSON data into a struct to access geographic information.</p>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(golangLocationExample)}>
            {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="go" style={atomDark} className="code-block">
            {golangLocationExample}
          </SyntaxHighlighter>
        </div>
        <p>This example shows how to decode JSON data representing a location into a Go struct for easy access.</p>
      </section>
    </div>
  );
};

export default JSONWithGolang;
