import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../cssfiles/JSONContent.css'; // Importing CSS file
const JsonArray = () => {
  const initialJsonExample = `["Apple", "Samsung", "Google"]`; // Example JSON array
  const [copied, setCopied] = useState(false); // State to manage copied feedback
  const [copiedText, setCopiedText] = useState(''); // State to manage copied text feedback

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedText(text);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
        setCopiedText('');
      }, 2000);
    });
  };

  return (
    <div className="json-introduction-container">
      <section>
        <h1>What is a JSON Array?</h1>
        <p>
          JSON (JavaScript Object Notation) is a lightweight data interchange format that can represent complex data structures, including arrays. A JSON array is a collection of values that is enclosed in square brackets.
        </p>
        <p>Example of a JSON Array:</p>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(initialJsonExample)}>
            {copied && copiedText === initialJsonExample ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="json" style={atomDark} className="code-block">
            {initialJsonExample}
          </SyntaxHighlighter>
        </div>
        <p>In this JSON array, we have three elements: "Apple," "Samsung," and "Google."</p>

        <h2>JSON Array Structure</h2>
        <p>JSON arrays can only contain the following data types:</p>
        <ul>
          <li>String</li>
          <li>Number</li>
          <li>Object</li>
          <li>Array</li>
          <li>Boolean</li>
          <li>Null</li>
        </ul>
        <p>In contrast, JavaScript arrays are more flexible and can contain any valid JavaScript values, including functions, dates, and undefined values.</p>
        
        <hr className="divider" />
        <h2>Defining JavaScript Arrays</h2>
        <p>You can create a JavaScript array using an array literal:</p>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(`let brands = ["Apple", "Samsung", "Google"];\nconsole.log("Brands Array:", brands);`)}>
            {copied && copiedText === `let brands = ["Apple", "Samsung", "Google"];\nconsole.log("Brands Array:", brands);` ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="javascript" style={atomDark}>
            {`let brands = ["Apple", "Samsung", "Google"];
console.log("Brands Array:", brands); // Outputs: Brands Array: [ 'Apple', 'Samsung', 'Google' ]`}
          </SyntaxHighlighter>
        </div>
        
        <h2>Converting JSON Strings to JavaScript Arrays</h2>
        <p>You can convert a JSON string into a JavaScript array using <code>JSON.parse()</code>:</p>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(`let jsonArray = '["Apple", "Samsung", "Google"]';\nlet brandsArray = JSON.parse(jsonArray);\nconsole.log("Brands Array from JSON:", brandsArray);`)}>
            {copied && copiedText === `let jsonArray = '["Apple", "Samsung", "Google"]';\nlet brandsArray = JSON.parse(jsonArray);\nconsole.log("Brands Array from JSON:", brandsArray);` ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="javascript" style={atomDark}>
            {`let jsonArray = '["Apple", "Samsung", "Google"]';
let brandsArray = JSON.parse(jsonArray);
console.log("Brands Array from JSON:", brandsArray); // Outputs: Brands Array from JSON: [ 'Apple', 'Samsung', 'Google' ]`}
          </SyntaxHighlighter>
        </div>

        <h2>Accessing Elements in an Array</h2>
        <p>You can access individual elements in a JavaScript array using their index:</p>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(`let brandsArray = ["Apple", "Samsung", "Google"];\nlet firstBrand = brandsArray[0];\nconsole.log("First Brand:", firstBrand);`)}>
            {copied && copiedText === `let brandsArray = ["Apple", "Samsung", "Google"];\nlet firstBrand = brandsArray[0];\nconsole.log("First Brand:", firstBrand);` ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="javascript" style={atomDark}>
            {`let brandsArray = ["Apple", "Samsung", "Google"];
let firstBrand = brandsArray[0];
console.log("First Brand:", firstBrand); // Outputs: First Brand: Apple`}
          </SyntaxHighlighter>
        </div>

        <h2>Using Arrays Inside Objects</h2>
        <p>JavaScript objects can include arrays as values. Here’s an example:</p>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(`let myObj = {\n  name: "Alice",\n  age: 25,\n  devices: ["iPhone", "MacBook", "iPad"]\n};\nlet firstDevice = myObj.devices[0];\nconsole.log("First Device:", firstDevice);`)}>
            {copied && copiedText === `let myObj = {\n  name: "Alice",\n  age: 25,\n  devices: ["iPhone", "MacBook", "iPad"]\n};\nlet firstDevice = myObj.devices[0];\nconsole.log("First Device:", firstDevice);` ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="javascript" style={atomDark}>
            {`let myObj = {
  name: "Alice",
  age: 25,
  devices: ["iPhone", "MacBook", "iPad"]
};

let firstDevice = myObj.devices[0];
console.log("First Device:", firstDevice); // Outputs: First Device: iPhone`}
          </SyntaxHighlighter>
        </div>

        <h2>Iterating Over an Array with a for...in Loop</h2>
        <p>You can loop through the values of an array using a for...in loop:</p>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(`let myObj = {\n  devices: ["iPhone", "MacBook", "iPad"]\n};\nconsole.log("Devices using for...in loop:");\nfor (let index in myObj.devices) {\n  console.log(myObj.devices[index]);\n}`)}>
            {copied && copiedText === `let myObj = {\n  devices: ["iPhone", "MacBook", "iPad"]\n};\nconsole.log("Devices using for...in loop:");\nfor (let index in myObj.devices) {\n  console.log(myObj.devices[index]);\n}` ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="javascript" style={atomDark}>
            {`let myObj = {
  devices: ["iPhone", "MacBook", "iPad"]
};

console.log("Devices using for...in loop:");
for (let index in myObj.devices) {
  console.log(myObj.devices[index]);
}`}
          </SyntaxHighlighter>
        </div>
      </section>
      <hr className="divider" />
    </div>
  );
};

export default JsonArray;
