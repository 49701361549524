import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../cssfiles/JSONContent.css'; // Importing CSS file

const JSONApiVersioning = () => {
  const urlPathVersions = `GET /api/v1/users\nGET /api/v2/users`;
  const queryParamVersions = `GET /api/users?version=1\nGET /api/users?version=2`;
  const headerVersioning = `Accept: application/vnd.example.v1+json`;

  const [copied, setCopied] = useState('');

  const handleCopy = (codeSnippet) => {
    navigator.clipboard.writeText(codeSnippet).then(() => {
      setCopied(codeSnippet); // Store the copied code for feedback
      setTimeout(() => setCopied(''), 2000); // Clear feedback after 2 seconds
    });
  };

  return (
    <div className="json-introduction-container">
      <section>
        <h2>What is JSON API Versioning?</h2>
        <ul>
          <li>JSON API versioning helps manage changes to an API over time.</li>
          <li>It ensures backward compatibility for existing clients.</li>
          <li>Clients can interact with the API without breaking their integrations.</li>
          <li>New features or changes can be added while keeping older versions available.</li>
        </ul>
      </section>

      <hr className="divider" />

      <section>
        <h2>Key Concepts of JSON API Versioning</h2>
        <h3>Versioning Strategies:</h3>
        <ul>
          <li>
            <strong>URL Path Versioning:</strong>
            <div className="code-block-container">
              <span
                className="copy-feedback"
                onClick={() => handleCopy(urlPathVersions)}
              >
                {copied === urlPathVersions ? 'Copied!' : <i className="fas fa-clone"></i>}
              </span>
              <SyntaxHighlighter language="bash" style={atomDark} className="code-block">
                {urlPathVersions}
              </SyntaxHighlighter>
            </div>
            <p>This strategy includes the version number in the API endpoint URL.</p>
          </li>
          <li>
            <strong>Query Parameter Versioning:</strong>
            <div className="code-block-container">
              <span
                className="copy-feedback"
                onClick={() => handleCopy(queryParamVersions)}
              >
                {copied === queryParamVersions ? 'Copied!' : <i className="fas fa-clone"></i>}
              </span>
              <SyntaxHighlighter language="bash" style={atomDark} className="code-block">
                {queryParamVersions}
              </SyntaxHighlighter>
            </div>
            <p>This method specifies the version as a query parameter in the API request.</p>
          </li>
          <li>
            <strong>Header Versioning:</strong>
            <div className="code-block-container">
              <span
                className="copy-feedback"
                onClick={() => handleCopy(headerVersioning)}
              >
                {copied === headerVersioning ? 'Copied!' : <i className="fas fa-clone"></i>}
              </span>
              <SyntaxHighlighter language="bash" style={atomDark} className="code-block">
                {headerVersioning}
              </SyntaxHighlighter>
            </div>
            <p>The version is specified in the HTTP headers of the request.</p>
          </li>
        </ul>
      </section>

      <hr className="divider" />

      <section>
        <h2>Example of JSON API Versioning</h2>
        <p>Here's a simple example illustrating URL path versioning for a user API:</p>
        <div className="code-block-container">
          <span
            className="copy-feedback"
            onClick={() => handleCopy(`${urlPathVersions}\nResponse:\n{\n    "id": 1,\n    "name": "Alice",\n    "email": "alice@example.com"\n}\n\n${urlPathVersions}\nResponse:\n{\n    "id": 1,\n    "name": "Alice",\n    "email": "alice@example.com",\n    "phone": "123-456-7890" // New field in version 2\n}`)}
          >
            {copied === `${urlPathVersions}\nResponse:\n{\n    "id": 1,\n    "name": "Alice",\n    "email": "alice@example.com"\n}\n\n${urlPathVersions}\nResponse:\n{\n    "id": 1,\n    "name": "Alice",\n    "email": "alice@example.com",\n    "phone": "123-456-7890" // New field in version 2\n}` ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="javascript" style={atomDark} className="code-block">
            {`${urlPathVersions}\nResponse:\n{\n    "id": 1,\n    "name": "Alice",\n    "email": "alice@example.com"\n}\n\nGET /api/v2/users\nResponse:\n{\n    "id": 1,\n    "name": "Alice",\n    "email": "alice@example.com",\n    "phone": "123-456-7890" // New field in version 2\n}`}
          </SyntaxHighlighter>
        </div>
      </section>
    </div>
  );
};

export default JSONApiVersioning;
