import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../cssfiles/JSONContent.css'; // Importing CSS file

const JSONWithCSharp = () => {
  // Sample JSON objects for demonstration
  const csharpUserExample = `using System;
using Newtonsoft.Json;

public class User {
    public string Username { get; set; }
    public int Age { get; set; }
    public bool IsVerified { get; set; }
}

class Program {
    static void Main() {
        User user = new User { Username = "john_doe", Age = 30, IsVerified = true };
        string userJson = JsonConvert.SerializeObject(user);
        Console.WriteLine(userJson);
        // Output: {"Username":"john_doe","Age":30,"IsVerified":true}
    }
}`;

  const csharpProductExample = `using System;
using Newtonsoft.Json;

public class Product {
    public string Name { get; set; }
    public decimal Price { get; set; }
    public bool Available { get; set; }
}

class Program {
    static void Main() {
        string jsonData = "{\"Name\":\"Smartphone\",\"Price\":799.99,\"Available\":true}";
        Product product = JsonConvert.DeserializeObject<Product>(jsonData);
        Console.WriteLine($"Product: {product.Name}, Price: {product.Price}, Available: {product.Available}");
        // Output: Product: Smartphone, Price: 799.99, Available: True
    }
}`;

  const csharpLocationExample = `using System;
using Newtonsoft.Json;

public class Location {
    public string City { get; set; }
    public string State { get; set; }
    public string Country { get; set; }
}

class Program {
    static void Main() {
        string jsonData = "{\"City\":\"New York\",\"State\":\"NY\",\"Country\":\"USA\"}";
        Location location = JsonConvert.DeserializeObject<Location>(jsonData);
        Console.WriteLine($"Location: {location.City}, {location.State}, {location.Country}");
        // Output: Location: New York, NY, USA
    }
}`;

  const [jsonData, setJsonData] = useState(csharpUserExample);
  const [copied, setCopied] = useState(false);

  const handleCopy = (codeSnippet) => {
    navigator.clipboard.writeText(codeSnippet).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className="json-introduction-container">
      <section>
        <h2>Using JSON with C#</h2>
        <p>In C#, JSON serialization and deserialization are commonly performed using the <code>Newtonsoft.Json</code> library, allowing for easy conversion between C# objects and JSON format.</p>
      </section>

      <hr className="divider" />

      <section>
        <h3>User Example</h3>
        <p><strong>Serialization</strong>: This example demonstrates how to serialize a C# object to JSON format.</p>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(csharpUserExample)}>
            {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="csharp" style={atomDark} className="code-block">
            {csharpUserExample}
          </SyntaxHighlighter>
        </div>
        <p>This code snippet shows how to convert a User object into a JSON string and print it to the console.</p>
      </section>

      <hr className="divider" />

      <section>
        <h3>Product Example</h3>
        <p><strong>Deserialization</strong>: This example demonstrates how to deserialize a JSON string into a C# object.</p>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(csharpProductExample)}>
            {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="csharp" style={atomDark} className="code-block">
            {csharpProductExample}
          </SyntaxHighlighter>
        </div>
        <p>This example shows how to parse a JSON string to retrieve Product details.</p>
      </section>

      <hr className="divider" />

      <section>
        <h3>Location Example</h3>
        <p><strong>Deserialization</strong>: This example illustrates deserializing a JSON string representing a location into a C# object.</p>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(csharpLocationExample)}>
            {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="csharp" style={atomDark} className="code-block">
            {csharpLocationExample}
          </SyntaxHighlighter>
        </div>
        <p>This example parses a JSON string to extract the Location information.</p>
      </section>
    </div>
  );
};

export default JSONWithCSharp;
