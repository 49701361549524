import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import useLocation for active route tracking
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai'; // Modern icons
import '../cssfiles/JsonSidebar.css'; // Update the path if needed

function Sidebar() {
  const [activeSections, setActiveSections] = useState({});
  const location = useLocation(); // Get the current route

  // Toggle the visibility of sections
  const toggleSection = (section) => {
    setActiveSections((prevSections) => ({
      ...prevSections,
      [section]: !prevSections[section],
    }));
  };

  // Render icon (plus/minus) based on active section
  const renderIcon = (section) =>
    activeSections[section] ? <AiOutlineMinus className="icon" /> : <AiOutlinePlus className="icon" />;

  // Check if a link should have 'active' class
  const isActive = (path) => (location.pathname === path ? 'active' : '');

  return (
    <div className="sidebar">
      <h2 className="sidebar-header">JSON Tutorials</h2> {/* Added Header */}
      
      {/* JSON Basics Section */}
      <div className="sidebar-section">
        <h4 onClick={() => toggleSection('jsonBasics')} className="toggle-header smaller-header">
          JSON Basics {renderIcon('jsonBasics')}
        </h4>
        {activeSections['jsonBasics'] && (
          <div className="sidebar-items">
            <Link to="/json/introduction" className={isActive('/json/introduction')}>JSON Introduction</Link>
            <Link to="/json/syntax" className={isActive('/json/syntax')}>JSON Syntax</Link>
            <Link to="/json/key-pairs" className={isActive('/json/key-pairs')}>JSON Key Pairs</Link>
            <Link to="/json/objects" className={isActive('/json/objects')}>JSON Objects</Link>
            <Link to="/json/arrays" className={isActive('/json/arrays')}>JSON Arrays</Link>
            <Link to="/json/datatypes" className={isActive('/json/datatypes')}>JSON Data Types</Link>
            <Link to="/json/jsonvsxml" className={isActive('/json/jsonvsxml')}>JSON vs. XML</Link>

          </div>
        )}
      </div>

      {/* JSON in Languages Section */}
      <div className="sidebar-section">
        <h4 onClick={() => toggleSection('jsonLanguages')} className="toggle-header smaller-header">
          JSON in Languages {renderIcon('jsonLanguages')}
        </h4>
        {activeSections['jsonLanguages'] && (
          <div className="sidebar-items">
            <Link to="/json/withjs" className={isActive('/json/withjs')}>JSON with JavaScript</Link>
            <Link to="/json/withpython" className={isActive('/json/withpython')}>JSON with Python</Link>
            <Link to="/json/withjava" className={isActive('/json/withjava')}>JSON with Java</Link>
            <Link to="/json/withgolang" className={isActive('/json/withgolang')}>JSON with Golang</Link>
            <Link to="/json/withcsharp" className={isActive('/json/withcsharp')}>JSON with C#</Link>
            <Link to="/json/withphp" className={isActive('/json/withphp')}>JSON with PHP</Link>
            <Link to="/json/withruby" className={isActive('/json/withruby')}>JSON with Ruby</Link>
          </div>
        )}
      </div>

      {/* JSON in Web Development Section */}
      <div className="sidebar-section">
        <h4 onClick={() => toggleSection('jsonWebDev')} className="toggle-header smaller-header">
          JSON in Web Development {renderIcon('jsonWebDev')}
        </h4>
        {activeSections['jsonWebDev'] && (
          <div className="sidebar-items">
            <Link to="/json/withajax" className={isActive('/json/withajax')}>JSON with AJAX</Link>
            <Link to="/json/withnodejs" className={isActive('/json/withnodejs')}>JSON in Node.js</Link>
            <Link to="/json/withrestapi" className={isActive('/json/withrestapi')}>JSON in REST APIs</Link>
          </div>
        )}
      </div>

      {/* JSON Advanced Topics Section */}
      <div className="sidebar-section">
        <h4 onClick={() => toggleSection('advancedJson')} className="toggle-header smaller-header">
          JSON Advanced Topics {renderIcon('advancedJson')}
        </h4>
        {activeSections['advancedJson'] && (
          <div className="sidebar-items">
            <Link to="/json/schema" className={isActive('/json/schema')}>JSON Schema</Link>
            <Link to="/json/webtokens" className={isActive('/json/webtokens')}>JSON Web Tokens</Link>
            <Link to="/json/customconversions" className={isActive('/json/customconversions')}>JSON Custom Conversions</Link>
          </div>
        )}
      </div>

      {/* JSON Practical Applications Section */}
      <div className="sidebar-section">
        <h4 onClick={() => toggleSection('practicalJson')} className="toggle-header smaller-header">
          JSON Utilization in Apps {renderIcon('practicalJson')}
        </h4>
        {activeSections['practicalJson'] && (
          <div className="sidebar-items">
            <Link to="/JSON/config-setting" className={isActive('/json/config-setting')}>JSON Configuration Settings</Link>
            <Link to="/json/errorhandling" className={isActive('/json/errorhandling')}>JSON Error Handling</Link>
            <Link to="/json/versioning" className={isActive('/json/versioning')}>JSON API Versioning</Link>
            <Link to="/json/databases" className={isActive('/json/databases')}>JSON and Databases</Link>
          </div>
        )}
      </div>

      {/* JSON Tools and Libraries Section */}
      <div className="sidebar-section">
        <h4 onClick={() => toggleSection('jsonTools')} className="toggle-header smaller-header">
          JSON Tools & Libraries {renderIcon('jsonTools')}
        </h4>
        {activeSections['jsonTools'] && (
          <div className="sidebar-items">
            <Link to="/json/libraries" className={isActive('/json/libraries')}>Popular JSON Libraries</Link>
            <Link to="/json/linters" className={isActive('/json/linters')}>JSON Linters</Link>
            <Link to="/json/validators" className={isActive('/json/validators')}>JSON Validators</Link>
            <Link to="/json/conversions" className={isActive('/json/conversions')}>JSON Format Conversions</Link>
            <Link to="/json/minify" className={isActive('/json/minify')}>Minifying JSON</Link>
          </div>
        )}
      </div>

      {/* JSON Security and Best Practices Section */}
      <div className="sidebar-section">
        <h4 onClick={() => toggleSection('jsonSecurity')} className="toggle-header smaller-header">
          JSON Best Practices {renderIcon('jsonSecurity')}
        </h4>
        {activeSections['jsonSecurity'] && (
          <div className="sidebar-items">
            <Link to="/json/security" className={isActive('/json/security')}>JSON Security</Link>
            <Link to="/json/bestpractices" className={isActive('/json/bestpractices')}>JSON API Best Practices</Link>
            <Link to="/json/optimization" className={isActive('/json/optimization')}>JSON Performance Optimization</Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default Sidebar;
