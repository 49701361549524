import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../cssfiles/JSONContent.css'; // Importing CSS file

const JSONSecurity = () => {
  const securityPoints = [
    {
      title: "1. Validate JSON Input",
      description: "Always validate JSON input to ensure it meets your application's expected format and data types. Use libraries or custom validators to avoid invalid or malicious data.",
      code: `// Example: JSON validation in JavaScript
try {
    const jsonData = JSON.parse(userInput);
    if (typeof jsonData === "object") {
        console.log("Valid JSON data:", jsonData);
    } else {
        throw new Error("Invalid JSON format");
    }
} catch (error) {
    console.error("JSON validation error:", error.message);
}`,
      output: `// Output:
Valid JSON data or error message`,
    },
    {
      title: "2. Avoid Parsing Untrusted JSON",
      description: "Parsing JSON from untrusted sources can introduce security risks. Only parse JSON data from trusted sources.",
      code: `// Example: Only parse trusted data
const trustedData = '{"name": "Alice", "age": 25}';
try {
    const parsedData = JSON.parse(trustedData);
    console.log("Trusted data:", parsedData);
} catch (error) {
    console.error("Parsing error:", error.message);
}`,
      output: `// Output:
Trusted data: { name: 'Alice', age: 25 }`,
    },
    {
      title: "3. Limit JSON Depth",
      description: "Limit the nesting depth of JSON objects to prevent deeply nested JSON structures that could lead to excessive processing.",
      code: `// Example: Check JSON depth
function checkDepth(obj, depth = 0) {
    if (depth > 5) throw new Error("Too deeply nested");
    if (typeof obj === 'object') {
        for (let key in obj) {
            checkDepth(obj[key], depth + 1);
        }
    }
}
checkDepth(JSON.parse('{"level1": {"level2": {"level3": {}}}}'));`,
      output: `// Output:
No error if depth is acceptable, error if too deeply nested`,
    },
    {
      title: "4. Sanitize JSON Data",
      description: "Sanitize JSON data before processing to remove any unexpected or dangerous data.",
      code: `// Example: Sanitize JSON properties
const sanitize = (json) => {
    for (let key in json) {
        if (typeof json[key] === 'string') {
            json[key] = json[key].replace(/[<>"'&]/g, '');
        }
    }
    return json;
};
const sanitizedData = sanitize(JSON.parse('{"name": "<script>"}'));
console.log(sanitizedData);`,
      output: `// Output:
{ name: '' }`,
    },
    {
      title: "5. Use Strict JSON Parsing",
      description: "Strictly control JSON parsing to avoid evaluating potentially harmful expressions. Use JSON.parse instead of eval.",
      code: `// Example: Avoid using eval with JSON
const jsonString = '{"name": "Alice", "age": 25}';
try {
    const safeData = JSON.parse(jsonString);
    console.log("Safe data:", safeData);
} catch (error) {
    console.error("Parsing error:", error.message);
}`,
      output: `// Output:
Safe data: { name: 'Alice', age: 25 }`,
    },
    {
      title: "6. Limit Data Size",
      description: "Limit the size of JSON payloads to prevent overwhelming the system with large data.",
      code: `// Example: Check JSON data size
const jsonData = '{"name": "Alice", "bio": "..."}'; // Example data
if (jsonData.length > 1000) {
    console.error("JSON data is too large");
} else {
    console.log("JSON data size is acceptable");
}`,
      output: `// Output:
JSON data size is acceptable or error if too large`,
    },
    {
      title: "7. Avoid Sensitive Data in JSON",
      description: "Avoid embedding sensitive information in JSON data that may be exposed to the client.",
      code: `// Example: Exclude sensitive data
const user = { name: "Alice", password: "secret" };
const safeData = JSON.stringify({ name: user.name }); // Only include public data
console.log(safeData);`,
      output: `// Output:
{ name: 'Alice' }`,
    }
  ];

  const [copied, setCopied] = useState(null);

  const handleCopy = (codeSnippet, index) => {
    navigator.clipboard.writeText(codeSnippet).then(() => {
      setCopied(index);
      setTimeout(() => setCopied(null), 2000);
    });
  };

  return (
    <div className="json-introduction-container">
      <section>
        <h2>JSON Security Best Practices</h2>
        <p>Below are important guidelines and examples to help ensure secure handling of JSON data in your application.</p>
      </section>

      {securityPoints.map((point, index) => (
        <div key={index} className="security-point">
          <h3>{point.title}</h3>
          <p>{point.description}</p>
          <div className="code-block-container">
            <span
              className="copy-feedback"
              onClick={() => handleCopy(point.code, index)}
            >
              {copied === index ? 'Copied!' : <i className="fas fa-clone"></i>}
            </span>
            <SyntaxHighlighter language="javascript" style={atomDark} className="code-block">
              {point.code}
            </SyntaxHighlighter>
          </div>
          <p><strong>Output:</strong> <pre>{point.output}</pre></p>
        </div>
      ))}
    </div>
  );
};

export default JSONSecurity;
