import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../cssfiles/JSONContent.css'; // Importing CSS file

const JSONValidator = () => {
  // Example JSON schema validation
  const jsonValidationExample = `{
    "schema": {
      "$schema": "http://json-schema.org/draft-07/schema#",
      "title": "Person",
      "type": "object",
      "properties": {
        "name": {
          "type": "string"
        },
        "age": {
          "type": "integer",
          "minimum": 0
        },
        "email": {
          "type": "string",
          "format": "email"
        }
      },
      "required": ["name", "age"]
    },
    "data": {
      "name": "Alice",
      "age": 30,
      "email": "alice@example.com"
    }
  }`;

  const [copied, setCopied] = useState(false);

  const handleCopy = (codeSnippet) => {
    navigator.clipboard.writeText(codeSnippet).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className="json-introduction-container">
      <section>
        <h2>What is a JSON Validator?</h2>
        <p>
          A JSON Validator checks if a JSON document conforms to a specified schema.
          Validation helps ensure JSON data integrity, ensuring it meets expected formats and structures.
        </p>
      </section>

      <hr className="divider" />

      <section>
        <h2>Popular JSON Validation Tools</h2>
        <ul>
          <li>
            <strong><a href="https://json-schema.org" target="_blank" rel="noopener noreferrer">JSON Schema</a></strong>: 
            The standard for JSON data validation, used to define schemas for data structure.
          </li>
          <li>
            <strong><a href="https://ajv.js.org/" target="_blank" rel="noopener noreferrer">Ajv</a></strong>: 
            A fast JavaScript JSON schema validator supporting draft-07 and JSON Schema Drafts.
          </li>
          <li>
            <strong><a href="https://jsonlint.com" target="_blank" rel="noopener noreferrer">jsonlint</a></strong>: 
            A web-based tool to validate JSON syntax and structure.
          </li>
          <li>
            <strong><a href="https://www.schemavalidator.com" target="_blank" rel="noopener noreferrer">Schema Validator</a></strong>:
            An online tool to validate JSON against a schema.
          </li>
        </ul>
        <p>This example demonstrates JSON validation using a JSON schema.</p>
      </section>

      <hr className="divider" />

      <section>
        <h3>Example JSON Schema Validation</h3>
        <p><strong>Validation</strong>: This JSON file includes both a schema and data to validate.</p>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(jsonValidationExample)}>
            {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="json" style={atomDark} className="code-block">
            {jsonValidationExample}
          </SyntaxHighlighter>
        </div>
        <p>
          Tools like <a href="https://ajv.js.org/" target="_blank" rel="noopener noreferrer">Ajv</a> and <a href="https://jsonlint.com" target="_blank" rel="noopener noreferrer">jsonlint</a> 
          can validate JSON data against schemas and check its integrity.
        </p>
      </section>
    </div>
  );
};

export default JSONValidator;
