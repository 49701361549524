import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Header from './components/mainsites/Header';
import JsonSidebar from './components/json/jsfiles/JsonSidebar';
import Search from './components/mainsites/Search';
import JSONIntroduction from './components/json/jsfiles/1.JSONIntroduction';
import JSONSyntax from './components/json/jsfiles/2.JSONSyntax';
import JSONKeyPairs from './components/json/jsfiles/3.JSONKeyPairs';
import JSONObjects from './components/json/jsfiles/4.JSONObjects';
import JSONOArray from './components/json/jsfiles/5.JsonArray';
import RightSidebar from './components/json/jsfiles/RightSidebar';
import JSONDataTypes from './components/json/jsfiles/6.JSONDatatypes';
import JSONvsXML from './components/json/jsfiles/7.JSONvsXML';
import JSONWithJavaScript from './components/json/jsfiles/8.JSONwithjs';
import JSONWithPython from './components/json/jsfiles/9.JSONwithpython';
import JSONWithJava from './components/json/jsfiles/10.JSONWithJava';
import JSONWithGolang from './components/json/jsfiles/11.JSONWithGolang';
import JSONWithCSharp from './components/json/jsfiles/12.JSONWithCSharp';
import JSONWithPHP from './components/json/jsfiles/13.JSONWithPHP';
import JSONWithRuby from './components/json/jsfiles/14.JSONWithRuby';
import JSONWithAjax from './components/json/jsfiles/15.JSONWithAjax';
import JSONWithNodeJS from './components/json/jsfiles/16.JSONWithNodeJS';
import JSONWithRestAPI from './components/json/jsfiles/17.JSONWithRestAPI';
import JSONSchema from './components/json/jsfiles/18.JSONSchema';
import JSONWebToken from './components/json/jsfiles/19.JSONWebToken';
import JSONCustomConversions from './components/json/jsfiles/20.JSONCustomConversions';
import JSONConfigurationSettings from './components/json/jsfiles/23.JSONConfigurationSettings';
import JSONErrorHandling from './components/json/jsfiles/24.JSONErrorHandling';
import JSONApiVersioning from './components/json/jsfiles/25.JSONApiVersioning';
import JSONDatabase from './components/json/jsfiles/26.JSONDatabase';
import JSONLibrary from './components/json/jsfiles/27.JSONLibrary';
import JSONLintor from './components/json/jsfiles/28.JSONLintor';
import JSONValidator from './components/json/jsfiles/29.JSONValidator';
import JSONFormatConversion from './components/json/jsfiles/30.JSONFormatConversion';
import JSONMinify from './components/json/jsfiles/31.JSONMinify';
import JSONSecurity from './components/json/jsfiles/32.JSONSecurity';
import JSONBestPractice from './components/json/jsfiles/33.JSONBestPractice';
import JSONPerfOpt from './components/json/jsfiles/34.JSONPerfOpt';

function App() {
    return (
        <Router>
            <div className="App">
                <Header />
                <main style={{ display: 'flex', height: 'calc(100vh - 60px)' }}>
                    <Routes>
                        <Route 
                            path="/json/*" 
                            element={
                                <div style={{ display: 'flex', flex: 1 }}>
                                    <JsonSidebar /> {/* Left Sidebar */}
                                    <div className="main-content" style={{ flex: 1, display: 'flex' }}>
                                        <div style={{ flex: 3, padding: '20px' }}> {/* Main content area */}
                                            <Routes>
                                                {/* Redirect /json to /json/introduction */}
                                                <Route path="/" element={<Navigate to="introduction" />} />
                                                <Route path="introduction" element={<JSONIntroduction />} />
                                                <Route path="syntax" element={<JSONSyntax />} />
                                                <Route path="key-pairs" element={<JSONKeyPairs />} />
                                                <Route path="objects" element={<JSONObjects />} />
                                                <Route path="arrays" element={<JSONOArray />} />
                                                <Route path="datatypes" element={<JSONDataTypes />} />
                                                <Route path="jsonvsxml" element={<JSONvsXML />} />
                                                
                                                {/* JSON in Languages Section */}
                                                <Route path="withjs" element={<JSONWithJavaScript />} />
                                                <Route path="withpython" element={<JSONWithPython />} />
                                                <Route path="withjava" element={<JSONWithJava />} />
                                                <Route path="withgolang" element={<JSONWithGolang />} />
                                                <Route path="withcsharp" element={<JSONWithCSharp />} />
                                                <Route path="withphp" element={<JSONWithPHP />} />
                                                <Route path="withruby" element={<JSONWithRuby />} />
                                                <Route path="security" element={<JSONSecurity />} />
                                                <Route path="bestpractices" element={<JSONBestPractice />} />
                                                <Route path="optimization" element={<JSONPerfOpt />} />
                                                <Route path="libraries" element={<JSONLibrary />} />
                                                <Route path="withajax" element={<JSONWithAjax />} />
                                                <Route path="withnodejs" element={<JSONWithNodeJS />} />
                                                <Route path="withrestapi" element={<JSONWithRestAPI />} />
                                                <Route path="schema" element={<JSONSchema />} />
                                                <Route path="webtokens" element={<JSONWebToken />} />
                                                <Route path="linters" element={<JSONLintor />} />
                                                <Route path="validators" element={<JSONValidator />} />
                                                <Route path="minify" element={<JSONMinify />} />
                                                <Route path="conversions" element={<JSONFormatConversion />} />
                                                <Route path="config-setting" element={<JSONConfigurationSettings />} />
                                                <Route path="errorhandling" element={<JSONErrorHandling />} />
                                                <Route path="versioning" element={<JSONApiVersioning />} />
                                                <Route path="databases" element={<JSONDatabase />} />
                                                <Route path="customconversions" element={<JSONCustomConversions />} />
                                            </Routes>
                                        </div>
                                        <RightSidebar /> {/* Right Sidebar consistently rendered */}
                                    </div>
                                </div>
                            } 
                        />
                        <Route path="/" element={<Search />} />
                    </Routes>
                </main>
            </div>
        </Router>
    );
}

export default App;
