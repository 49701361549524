import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../cssfiles/JSONContent.css'; // Importing CSS file

const JSONLinters = () => {
  // Example JSON file
  const jsonFileExample = `{
    "name": "Sample JSON",
    "age": 30,
    "city": "New York"
  }`;

  const [copied, setCopied] = useState(false);

  const handleCopy = (codeSnippet) => {
    navigator.clipboard.writeText(codeSnippet).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className="json-introduction-container">
      <section>
        <h2>What is a JSON Linter?</h2>
        <p>
          A JSON linter is a tool that checks JSON data for syntax errors and provides feedback to ensure the data is properly formatted. It helps developers maintain clean and valid JSON files.
        </p>
      </section>

      <hr className="divider" />

      <section>
        <h2>JSON Linter Tools</h2>
        <ul>
          <li>
            <strong><a href="https://jsonlint.com" target="_blank" rel="noopener noreferrer">JSONLint</a></strong>: A popular online tool for validating and formatting JSON.
            <p>Example: Paste your JSON at jsonlint.com.</p>
          </li>
          <li>
            <strong><a href="https://json-lint.com" target="_blank" rel="noopener noreferrer">json-lint.com</a></strong>: A simple and effective online linter for checking JSON validity.
            <p>Example: Use this service at json-lint.com.</p>
          </li>
          <li>
            <strong><a href="https://ajv.js.org" target="_blank" rel="noopener noreferrer">AJV</a></strong>: A fast JSON Schema validator used in various applications.
            <p>Example: Integrate AJV in your project from GitHub.</p>
          </li>
        </ul>
      </section>

      <hr className="divider" />

      <section>
        <h3>Example JSON</h3>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(jsonFileExample)}>
            {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="json" style={atomDark} className="code-block">
            {jsonFileExample}
          </SyntaxHighlighter>
        </div>
        <p>This JSON example represents a simple user profile with a name, age, and city.</p>
      </section>
    </div>
  );
};

export default JSONLinters;
