import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../cssfiles/JSONContent.css'; // Importing CSS file

const JSONBestPractice = () => {
  const examples = [
    {
      title: "1. Use Consistent Naming Conventions",
      description: "Stick to camelCase for property names to maintain readability.",
      code: `const userData = {
    "userName": "EmmaWatson",
    "userAge": 30,
    "isActive": true
};`,
      output: `{
  "userName": "EmmaWatson",
  "userAge": 30,
  "isActive": true
}`,
      explanation: "Using camelCase consistently ensures that the data structure is predictable and readable."
    },
    {
      title: "2. Avoid Using Null Values",
      description: "Prefer empty strings or default values over nulls.",
      code: `const employee = {
    "name": "John Doe",
    "department": "Marketing",
    "manager": ""
};`,
      output: `{
  "name": "John Doe",
  "department": "Marketing",
  "manager": ""
}`,
      explanation: "Using empty strings or default values helps prevent null reference errors."
    },
    {
      title: "3. Use Arrays for Lists of Values",
      description: "When representing a collection, use arrays for consistency and easier parsing.",
      code: `const skills = {
    "programmingLanguages": ["JavaScript", "Python", "Go"]
};`,
      output: `{
  "programmingLanguages": ["JavaScript", "Python", "Go"]
}`,
      explanation: "Arrays clearly indicate that these values are a collection, making parsing easier."
    },
    {
      title: "4. Ensure Data Type Consistency",
      description: "Use the same data type for similar values across objects.",
      code: `const product = {
    "id": 102,
    "name": "Wireless Keyboard",
    "price": 49.99,
    "inStock": true
};`,
      output: `{
  "id": 102,
  "name": "Wireless Keyboard",
  "price": 49.99,
  "inStock": true
}`,
      explanation: "Keeping data types consistent helps prevent issues during data manipulation."
    },
    {
      title: "5. Avoid Redundant Information",
      description: "Keep JSON lean by avoiding duplicate or unnecessary information.",
      code: `const order = {
    "orderId": "A123",
    "customer": {
        "name": "Alice",
        "email": "alice@example.com"
    }
};`,
      output: `{
  "orderId": "A123",
  "customer": {
    "name": "Alice",
    "email": "alice@example.com"
  }
}`,
      explanation: "This ensures the JSON object is concise, minimizing data redundancy."
    },
    {
      title: "6. Use Meaningful Keys",
      description: "Choose descriptive keys to make the data self-explanatory.",
      code: `const transaction = {
    "transactionId": "TX456",
    "amount": 150.75,
    "currency": "USD"
};`,
      output: `{
  "transactionId": "TX456",
  "amount": 150.75,
  "currency": "USD"
}`,
      explanation: "Meaningful keys like 'transactionId' make the data easy to understand."
    },
    {
      title: "7. Document Your JSON Structure",
      description: "Provide documentation or comments outside the JSON data itself to clarify usage.",
      code: `// This JSON represents a person object with basic info
const person = {
    "name": "Sophia",
    "age": 29,
    "email": "sophia@example.com"
};`,
      output: `{
  "name": "Sophia",
  "age": 29,
  "email": "sophia@example.com"
}`,
      explanation: "Documenting outside the JSON helps other developers understand the structure without altering the data."
    },
    {
      title: "8. Maintain JSON Syntax Standards",
      description: "Follow JSON syntax strictly to avoid parsing issues.",
      code: `const location = {
    "city": "Seattle",
    "state": "Washington",
    "zip": "98109"
};`,
      output: `{
  "city": "Seattle",
  "state": "Washington",
  "zip": "98109"
}`,
      explanation: "Correct syntax, such as enclosing strings in double quotes, prevents parsing errors."
    }
  ];

  const [copied, setCopied] = useState(false);

  const handleCopy = (code) => {
    navigator.clipboard.writeText(code).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className="json-introduction-container">
      <h2>JSON Best Practices</h2>
      <p>Follow these JSON best practices to ensure consistent, readable, and maintainable JSON data.</p>
      {examples.map((example, index) => (
        <section key={index}>
          <h3>{example.title}</h3>
          <p><strong>{example.description}</strong></p>
          <div className="code-block-container">
            <span className="copy-feedback" onClick={() => handleCopy(example.code)}>
              {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
            </span>
            <SyntaxHighlighter language="javascript" style={atomDark} className="code-block">
              {example.code}
            </SyntaxHighlighter>
          </div>
          <p><strong>Output:</strong></p>
          <pre className="output-block">{example.output}</pre>
          <p><strong>Explanation:</strong> {example.explanation}</p>
          <hr className="divider" />
        </section>
      ))}
    </div>
  );
};

export default JSONBestPractice;
