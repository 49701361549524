import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../cssfiles/JSONContent.css'; // Importing CSS file

const JSONWithNodeJS = () => {
  const examples = [
    {
      title: "1. Creating a JSON Object",
      description: "You can create a JSON object in Node.js just like in the browser.",
      code: `const user = {
  "name": "Alex",
  "age": 25,
  "isStudent": false
};`,
      output: `{
  "name": "Alex",
  "age": 25,
  "isStudent": false
}`,
      explanation: "This JSON object represents a user with properties like name, age, and student status."
    },
    {
      title: "2. Reading JSON from a File",
      description: "Node.js allows you to read JSON data from a file easily.",
      code: `const fs = require('fs');

fs.readFile('data.json', 'utf8', (err, data) => {
  if (err) throw err;
  const jsonData = JSON.parse(data);
  console.log(jsonData);
});`,
      output: `// Output will depend on the contents of data.json`,
      explanation: "This code reads a JSON file and parses it into a JavaScript object."
    },
    {
      title: "3. Writing JSON to a File",
      description: "You can also write a JavaScript object as JSON to a file.",
      code: `const fs = require('fs');

const newUser = {
  "name": "Sara",
  "age": 30,
  "hobbies": ["reading", "travelling"]
};

fs.writeFile('newUser.json', JSON.stringify(newUser, null, 2), (err) => {
  if (err) throw err;
  console.log('JSON data has been written to newUser.json');
});`,
      output: `// A new file named newUser.json will be created with the JSON data.`,
      explanation: "This example demonstrates how to write a JavaScript object to a JSON file, making it easy to save data."
    },
    {
      title: "4. Sending JSON Response in Express",
      description: "In a Node.js server, you can send JSON responses using Express.",
      code: `const express = require('express');
const app = express();

app.get('/user', (req, res) => {
  const user = {
    "name": "Mike",
    "age": 28,
    "isAdmin": true
  };
  res.json(user);
});

app.listen(3000, () => {
  console.log('Server is running on http://localhost:3000');
});`,
      output: `// Accessing http://localhost:3000/user will return the user JSON object.`,
      explanation: "This example shows how to send a JSON response from an Express server."
    }
  ];

  const [copied, setCopied] = useState(false);

  const handleCopy = (code) => {
    navigator.clipboard.writeText(code).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className="json-introduction-container">
      <h2>JSON with Node.js</h2>
      <p>Explore how to handle JSON data in Node.js through various examples.</p>
      {examples.map((example, index) => (
        <section key={index}>
          <h3>{example.title}</h3>
          <p><strong>{example.description}</strong></p>
          <div className="code-block-container">
            <span className="copy-feedback" onClick={() => handleCopy(example.code)}>
              {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
            </span>
            <SyntaxHighlighter language="javascript" style={atomDark} className="code-block">
              {example.code}
            </SyntaxHighlighter>
          </div>
          <p><strong>Output:</strong></p>
          <pre className="output-block">{example.output}</pre>
          <p><strong>Explanation:</strong> {example.explanation}</p>
          <hr className="divider" />
        </section>
      ))}
    </div>
  );
};

export default JSONWithNodeJS;
