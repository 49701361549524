// src/components/JSONSyntax.js
import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../cssfiles/JSONContent.css'; // Importing CSS file

const JSONSyntax = () => {
    const syntaxExample = `{
        "name": "Johnshon",
        "age": 30,
        "isStudent": false,
        "courses": ["Mathematics", "Science"],
        "address": {
            "city": "New York",
            "zipCode": "10001"
        },
        "graduationYear": null
    }`;

    const [copied, setCopied] = useState(false);

    // Function to copy JSON content to clipboard
    const handleCopy = () => {
        navigator.clipboard.writeText(syntaxExample).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
        });
    };

    return (
        <div className="json-introduction-container">
            <h2>What is JSON Syntax?</h2>
            <div className="content-section">
                <ul className="info-list">
                    <li>Consists of key-value pairs.</li>
                    <li>Keys are always strings enclosed in double quotes.</li>
                    <li>Values can be:
                        <ul>
                            <li>Strings (in double quotes)</li>
                            <li>Numbers</li>
                            <li>Booleans (true/false)</li>
                            <li>Arrays (ordered lists enclosed in square brackets)</li>
                            <li>Objects (unordered collections of key-value pairs enclosed in curly braces)</li>
                            <li>Null (representing an empty value)</li>
                        </ul>
                    </li>
                </ul>
                <hr className="divider" />

                <h4 className="subheading">JSON Syntax Example</h4>
                <div className="code-block-container">
                    {/* Copy icon with feedback text */}
                    <span className="copy-feedback" onClick={handleCopy}>
                        {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
                    </span>
                    <SyntaxHighlighter language="json" style={atomDark} className="code-block">
                        {syntaxExample}
                    </SyntaxHighlighter>
                </div>
                <hr className="divider" />

                <h4 className="subheading">Detailed Breakdown of the Example</h4>
                <div className="details-section">
                    <h5>Objects:</h5>
                    <p>The entire structure is an object, indicated by the curly braces {`{ }`}.</p>

                    <h5>Key-Value Pairs:</h5>
                    <p>Each key is a string (e.g., "name", "age"), followed by a colon and then the value.</p>
                    <hr className="divider" />
                    <hr className="divider" />
                    <h5>Values:</h5>
                    <ul className="info-list">
                        <li><strong>String:</strong> "name": "Johnshon" - The value is a string in double quotes.</li>
                        <li><strong>Number:</strong> "age": 30 - The value is a number without quotes.</li>
                        <li><strong>Boolean:</strong> "isStudent": false - A boolean (true or false).</li>
                        <li><strong>Array:</strong> "courses": ["Mathematics", "Science"] - An array of strings in square brackets.</li>
                        <li><strong>Nested Object:</strong> "address": {`{"city": "New York", "zipCode": "10001"}`} - Another object with key-value pairs.</li>
                        <li><strong>Null:</strong> "graduationYear": null - A null value.</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default JSONSyntax;
