import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../cssfiles/JSONContent.css'; // Importing CSS file

const JSONLinters = () => {
  // Example JSON file with potential linting issues
  const jsonFileExample = `{
    "name": "Alice",
    "age": 30,
    "isStudent": false,
    "courses": [
      { "name": "Math", "grade": "A" },
      { "name": "History", "grade": "B" }
    ]
  }`;

  const [copied, setCopied] = useState(false);

  const handleCopy = (codeSnippet) => {
    navigator.clipboard.writeText(codeSnippet).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className="json-introduction-container">
      <section>
        <h2>What is a JSON Linter?</h2>
        <p>
          A JSON linter is a tool that checks JSON code for syntax errors, formatting issues,
          and adherence to best practices. Using a linter ensures consistency, readability, and 
          prevents syntax errors in JSON files.
        </p>
      </section>

      <hr className="divider" />

      <section>
        <h2>Popular JSON Linter Tools</h2>
        <ul>
          <li>
            <strong><a href="https://jsonlint.com" target="_blank" rel="noopener noreferrer">jsonlint</a></strong>:
            A web-based tool to validate and format JSON code.
          </li>
          <li>
            <strong><a href="https://eslint.org" target="_blank" rel="noopener noreferrer">ESLint</a></strong> with JSON plugins:
            Used primarily in JavaScript projects to lint JSON files.
          </li>
          <li>
            <strong><a href="https://prettier.io" target="_blank" rel="noopener noreferrer">Prettier</a></strong>:
            An automatic code formatter that can beautify JSON files.
          </li>
          <li>
            <strong><a href="https://stedolan.github.io/jq/" target="_blank" rel="noopener noreferrer">jq</a></strong>:
            A command-line tool for processing JSON, often used to validate and manipulate JSON in terminal.
          </li>
        </ul>
        <p>This example demonstrates <strong>jsonlint</strong> for checking JSON syntax and formatting.</p>
      </section>

      <hr className="divider" />

      <section>
        <h3>Example JSON File</h3>
        <p><strong>Validation</strong>: This JSON file represents a student with course information.</p>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(jsonFileExample)}>
            {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="json" style={atomDark} className="code-block">
            {jsonFileExample}
          </SyntaxHighlighter>
        </div>
        <p>Linting tools like <a href="https://jsonlint.com" target="_blank" rel="noopener noreferrer">jsonlint</a> can help ensure this JSON file is properly formatted and free of syntax errors.</p>
      </section>
    </div>
  );
};

export default JSONLinters;
