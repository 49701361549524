import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../cssfiles/JSONContent.css'; // Importing CSS file

const JSONSchema = () => {
  const examples = [
    {
      title: "1. Basic JSON Schema",
      description: "This example defines a simple JSON Schema that specifies the structure of a person object.",
      code: `{
  "$schema": "http://json-schema.org/draft-07/schema#",
  "type": "object",
  "properties": {
    "firstName": {
      "type": "string"
    },
    "lastName": {
      "type": "string"
    },
    "age": {
      "type": "integer"
    }
  },
  "required": ["firstName", "lastName"]
}`,
      output: `// This schema validates an object with firstName, lastName as strings, and age as an integer.`,
      explanation: "This schema requires that a person object includes firstName and lastName as string properties, with age as an optional integer.",
      validExample: `{
  "firstName": "John",
  "lastName": "Doe",
  "age": 30
}`,
      invalidExample: `{
  "firstName": 123,
  "lastName": "Doe"
}`,
      validOutput: `// Valid data matches the schema requirements.`,
      invalidOutput: `// Invalid data fails because firstName is not a string.`,
      codeLineExplanations: [
        { line: '$schema: "http://json-schema.org/draft-07/schema#"', explanation: "Specifies the schema version." },
        { line: 'type: "object"', explanation: "Indicates that the root type is an object." },
        { line: 'properties: {', explanation: "Defines properties of the object." },
        { line: 'firstName: { type: "string" }', explanation: "firstName must be a string." },
        { line: 'lastName: { type: "string" }', explanation: "lastName must be a string." },
        { line: 'age: { type: "integer" }', explanation: "age must be an integer." },
        { line: '}', explanation: "End of properties definition." },
        { line: 'required: ["firstName", "lastName"]', explanation: "Specifies that firstName and lastName are required." }
      ]
    },
    {
      title: "2. JSON Schema with Nested Objects",
      description: "This example demonstrates how to create a schema for an object with nested properties.",
      code: `{
  "$schema": "http://json-schema.org/draft-07/schema#",
  "type": "object",
  "properties": {
    "address": {
      "type": "object",
      "properties": {
        "street": { "type": "string" },
        "city": { "type": "string" }
      },
      "required": ["street", "city"]
    }
  }
}`,
      output: `// This schema validates an object that includes an address with street and city as required string properties.`,
      explanation: "This schema illustrates how to define nested objects, requiring both street and city for the address property.",
      validExample: `{
  "address": {
    "street": "123 Main St",
    "city": "Anytown"
  }
}`,
      invalidExample: `{
  "address": {
    "city": "Anytown"
  }
}`,
      validOutput: `// Valid data matches the schema requirements.`,
      invalidOutput: `// Invalid data fails because street is missing from the address.`
    }
  ];

  const [copied, setCopied] = useState(false);

  const handleCopy = (code) => {
    navigator.clipboard.writeText(code).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className="json-introduction-container">
      <h2>JSON Schema</h2>
      <p>JSON Schema is a powerful tool for validating the structure of JSON data. It allows you to define expected formats, types, and requirements for JSON objects, enabling better data interchange and ensuring data integrity.</p>
      {examples.map((example, index) => (
        <section key={index}>
          <h3>{example.title}</h3>
          <p><strong>{example.description}</strong></p>
          <div className="code-block-container">
            <span className="copy-feedback" onClick={() => handleCopy(example.code)}>
              {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
            </span>
            <SyntaxHighlighter language="json" style={atomDark} className="code-block">
              {example.code}
            </SyntaxHighlighter>
          </div>
          <p><strong>Output:</strong></p>
          <pre className="output-block">{example.output}</pre>
          <p><strong>Explanation:</strong> {example.explanation}</p>

          {example.title === "1. Basic JSON Schema" && (
            <>
              <h4>Code Line Explanations:</h4>
              <div className="line-explanations">
                {example.codeLineExplanations.map((lineObj, idx) => (
                  <div key={idx} className="note">
                    <strong>{lineObj.line}</strong> - <span>{lineObj.explanation}</span>
                  </div>
                ))}
              </div>
            </>
          )}

          <h4>Valid Example:</h4>
          <div className="code-block-container">
            <span className="copy-feedback" onClick={() => handleCopy(example.validExample)}>
              {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
            </span>
            <SyntaxHighlighter language="json" style={atomDark} className="code-block">
              {example.validExample}
            </SyntaxHighlighter>
          </div>
          <pre className="output-block">{example.validOutput}</pre>

          <h4>Invalid Example:</h4>
          <div className="code-block-container">
            <span className="copy-feedback" onClick={() => handleCopy(example.invalidExample)}>
              {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
            </span>
            <SyntaxHighlighter language="json" style={atomDark} className="code-block">
              {example.invalidExample}
            </SyntaxHighlighter>
          </div>
          <pre className="output-block">{example.invalidOutput}</pre>

          <hr className="divider" />
        </section>
      ))}
    </div>
  );
};

export default JSONSchema;
