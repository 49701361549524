// src/components/JSONKeyPairs.js
import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../cssfiles/JSONContent.css'; // Importing CSS file


const KeyValuePairs = () => {
    const examples = [
        {
            title: "Book Information",
            json: `{
  "title": "To Kill a Mockingbird",
  "author": "Harper Lee",
  "publishedYear": 1960,
  "genres": ["Fiction", "Drama"],
  "isAvailable": true,
  "rating": null
}`,
            breakdown: [
                { key: "title", value: "To Kill a Mockingbird (String)" },
                { key: "author", value: "Harper Lee (String)" },
                { key: "publishedYear", value: "1960 (Number)" },
                { key: "genres", value: '["Fiction", "Drama"] (Array)' },
                { key: "isAvailable", value: "true (Boolean)" },
                { key: "rating", value: "null (Null)" },
            ],
        },
        {
            title: "Employee Record",
            json: `{
  "employeeId": 12345,
  "name": "Alice Johnson",
  "department": "Engineering",
  "skills": ["Java", "React", "Node.js"],
  "isFullTime": true,
  "projects": [
    {
      "projectName": "Website Redesign",
      "duration": "3 months"
    },
    {
      "projectName": "Mobile App Development",
      "duration": "6 months"
    }
  ]
}`,
            breakdown: [
                { key: "employeeId", value: "12345 (Number)" },
                { key: "name", value: "Alice Johnson (String)" },
                { key: "department", value: "Engineering (String)" },
                { key: "skills", value: '["Java", "React", "Node.js"] (Array)' },
                { key: "isFullTime", value: "true (Boolean)" },
                { key: "projects", value: "Array of objects with key-value pairs." },
            ],
        },
        {
            title: "Movie Details",
            json: `{
  "movieTitle": "Inception",
  "director": "Christopher Nolan",
  "releaseYear": 2010,
  "duration": 148,
  "cast": [
    "Leonardo DiCaprio",
    "Joseph Gordon-Levitt",
    "Elliot Page"
  ],
  "boxOffice": {
    "budget": 160000000,
    "gross": 836836967
  },
  "isAwardWinner": true
}`,
            breakdown: [
                { key: "movieTitle", value: '"Inception" (String)' },
                { key: "director", value: '"Christopher Nolan" (String)' },
                { key: "releaseYear", value: "2010 (Number)" },
                { key: "duration", value: "148 (Number)" },
                { key: "cast", value: '["Leonardo DiCaprio", "Joseph Gordon-Levitt", "Elliot Page"] (Array)' },
                { key: "boxOffice", value: "Object with budget and gross." },
                { key: "isAwardWinner", value: "true (Boolean)" },
            ],
        },
        {
            title: "Student Profile",
            json: `{
  "studentId": "S001",
  "name": "Mark Smith",
  "age": 21,
  "enrolledCourses": ["Mathematics", "Physics", "Chemistry"],
  "GPA": 3.7,
  "isGraduated": false,
  "contactInfo": {
    "email": "mark.smith@example.com",
    "phone": "555-0123"
  }
}`,
            breakdown: [
                { key: "studentId", value: '"S001" (String)' },
                { key: "name", value: '"Mark Smith" (String)' },
                { key: "age", value: "21 (Number)" },
                { key: "enrolledCourses", value: '["Mathematics", "Physics", "Chemistry"] (Array)' },
                { key: "GPA", value: "3.7 (Number)" },
                { key: "isGraduated", value: "false (Boolean)" },
                { key: "contactInfo", value: "Object with email and phone." },
            ],
        },
        {
            title: "Weather Report",
            json: `{
  "location": "New York City",
  "temperature": 25.5,
  "conditions": "Partly Cloudy",
  "humidity": 60,
  "forecast": {
    "tomorrow": {
      "high": 28,
      "low": 19,
      "chanceOfRain": 20
    }
  }
}`,
            breakdown: [
                { key: "location", value: '"New York City" (String)' },
                { key: "temperature", value: "25.5 (Number)" },
                { key: "conditions", value: '"Partly Cloudy" (String)' },
                { key: "humidity", value: "60 (Number)" },
                { key: "forecast", value: "Object with tomorrow's forecast." },
            ],
        }
    ];

    const [copiedStates, setCopiedStates] = useState(Array(examples.length).fill(false)); // Initialize copied states

    // Function to copy JSON content to clipboard with feedback
    const handleCopy = (index) => {
        navigator.clipboard.writeText(examples[index].json).then(() => {
            const newCopiedStates = [...copiedStates];
            newCopiedStates[index] = true; // Set copied state for the current index
            setCopiedStates(newCopiedStates);
            setTimeout(() => {
                newCopiedStates[index] = false; // Reset after 2 seconds
                setCopiedStates(newCopiedStates);
            }, 2000);
        });
    };

    return (
        <div className="json-introduction-container">
            <h2>What is JSON Key Pairs?</h2>
            <p>JSON (JavaScript Object Notation) uses key-value pairs to represent data. Each pair consists of a key (a string) and a value, which can be a string, number, boolean, array, object, or null.</p>

            {examples.map((example, index) => (
                <div key={index}>
                    <h3>{example.title}</h3>
                    <div className="code-block-container">
                        <span className="copy-feedback" onClick={() => handleCopy(index)}>
                            {copiedStates[index] ? 'Copied!' : <i className="fas fa-clone"></i>}
                        </span>
                        <SyntaxHighlighter
                            language="json"
                            style={atomDark}
                            className="code-block"
                        >
                            {example.json}
                        </SyntaxHighlighter>
                    </div>
                    <h4>Breakdown:</h4>
                    <ul className="info-list">
                        {example.breakdown.map((item, idx) => (
                            <li key={idx}>
                                <strong>Key:</strong> "{item.key}" - <strong>Value:</strong> {item.value}
                            </li>
                        ))}
                    </ul>
                    <div className="divider"></div> {/* Adding a divider for visual separation */}
                </div>
            ))}
        </div>
    );
};

export default KeyValuePairs;
