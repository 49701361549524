import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../cssfiles/JSONContent.css'; // Importing CSS file

const JSONFormatConversion = () => {
  const jsonExample = `{
    "name": "Alice",
    "age": 30,
    "location": "New York"
  }`;

  const xmlExample = `<person>
    <name>Alice</name>
    <age>30</age>
    <location>New York</location>
  </person>`;

  const csvExample = `name,age,location
Alice,30,New York`;

  const yamlExample = `name: Alice
age: 30
location: New York`;

  const [copied, setCopied] = useState(false);

  const handleCopy = (codeSnippet) => {
    navigator.clipboard.writeText(codeSnippet).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className="json-introduction-container">
      <section>
        <h2>What is JSON Format Conversion?</h2>
        <p>
          JSON format conversion allows data to be translated from JSON to other formats like XML, CSV, or YAML, and vice versa. This is useful when integrating JSON with systems or applications that require other formats.
        </p>
      </section>

      <hr className="divider" />

      <section>
        <h2>Example of JSON Format Conversion</h2>
        <p>Below are examples of JSON data converted into XML, CSV, and YAML formats:</p>

        <h3>Original JSON</h3>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(jsonExample)}>
            {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="json" style={atomDark} className="code-block">
            {jsonExample}
          </SyntaxHighlighter>
        </div>

        <h3>Converted to XML</h3>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(xmlExample)}>
            {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="xml" style={atomDark} className="code-block">
            {xmlExample}
          </SyntaxHighlighter>
        </div>

        <h3>Converted to CSV</h3>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(csvExample)}>
            {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="plaintext" style={atomDark} className="code-block">
            {csvExample}
          </SyntaxHighlighter>
        </div>

        <h3>Converted to YAML</h3>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(yamlExample)}>
            {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="yaml" style={atomDark} className="code-block">
            {yamlExample}
          </SyntaxHighlighter>
        </div>

        <p>This demonstrates the flexibility of JSON by converting it to multiple formats.</p>
      </section>

      <hr className="divider" />

      <section>
        <h2>Popular JSON Conversion Tools</h2>
        <ul>
          <li><strong><a href="https://www.convertjson.com/json-to-xml.htm" target="_blank" rel="noopener noreferrer">Convert JSON to XML</a></strong></li>
          <li><strong><a href="https://json-csv.com/" target="_blank" rel="noopener noreferrer">JSON to CSV Converter</a></strong></li>
          <li><strong><a href="https://jsonformatter.org/json-to-yaml" target="_blank" rel="noopener noreferrer">JSON to YAML</a></strong></li>
          <li><strong><a href="https://codebeautify.org/json-to-html-converter" target="_blank" rel="noopener noreferrer">JSON to HTML</a></strong></li>
        </ul>
      </section>

      <hr className="divider" />


    </div>
  );
};

export default JSONFormatConversion;
