import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../cssfiles/JSONContent.css'; // Importing CSS file

const JSONErrorHandling = () => {
  const jsonErrorExample = `const jsonString = '{"name": "Alice", "age": "30"}'; // Note: age should be a number

try {
  const user = JSON.parse(jsonString);
  console.log("User data:", user);
} catch (error) {
  console.error("Invalid JSON data:", error.message);
}`;

  const [copied, setCopied] = useState(false);

  const handleCopy = (codeSnippet) => {
    navigator.clipboard.writeText(codeSnippet).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className="json-introduction-container">
      <section>
        <h2>What is JSON Error Handling?</h2>
        <p>
          JSON error handling is the process of managing errors that can occur when
          working with JSON data, especially when parsing or manipulating it. It helps
          ensure that applications can gracefully handle errors and provide feedback to users.
        </p>
      </section>

      <hr className="divider" />

      <section>
        <h2>Example of JSON Error Handling</h2>
        <p>The following example demonstrates how to handle errors when parsing JSON data:</p>
        <div className="code-block-container">
          <span className="copy-feedback" onClick={() => handleCopy(jsonErrorExample)}>
            {copied ? 'Copied!' : <i className="fas fa-clone"></i>}
          </span>
          <SyntaxHighlighter language="javascript" style={atomDark} className="code-block">
            {jsonErrorExample}
          </SyntaxHighlighter>
        </div>
        <div className="note">
          <p>In this example:</p>
          <ul>
            <li><strong>jsonString:</strong> A string representing JSON data. Here, the age is incorrectly formatted as a string.</li>
            <li><strong>try-catch:</strong> A block of code that attempts to parse the JSON string.</li>
            <li>If the parsing fails, the error is caught, and an error message is logged to the console.</li>
          </ul>
        </div>
      </section>

      <hr className="divider" />

      <section>
        <h2>Benefits of JSON Error Handling</h2>
        <ul>
          <li>Helps identify problems quickly, allowing for faster debugging.</li>
          <li>Improves user experience by providing clear feedback when errors occur.</li>
          <li>Ensures the application can continue running even if some JSON data is invalid.</li>
          <li>Facilitates easier maintenance by isolating error handling from the main application logic.</li>
        </ul>
      </section>
    </div>
  );
};

export default JSONErrorHandling;
